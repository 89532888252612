import React from 'react';
import { connect } from 'react-redux';
import NavBar from './Structure/NavBar';
import Drawer from './Structure/Drawer';
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";
import {initReactI18next, useTranslation} from 'react-i18next';
import { getBase } from './Api/RestApi'
import Language from './Language';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { withStyles } from "@material-ui/core/styles";
import theme from './theme.js';
import WebfontLoader from '@dr-kobros/react-webfont-loader';
import TagManager from 'react-gtm-module'

const tagManagerArgs = {
    gtmId: 'GTM-PXGTP63'
};

TagManager.initialize(tagManagerArgs)

const config = {
  google: {
    families: ['Montserrat:300,500,600,700&display=swap'],
  },
  active: () => {
      sessionStorage.fontsLoaded = true
  },
};

// Callback receives the status of the general webfont loading process. *OPTIONAL*
const callback = status => {
  // I could hook the webfont status to for example Redux here.
};

class App extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      base: this.props.base,
      cart: this.props.cart,
    };
  }

  theme() {

  }

  detectLang() {
    if(document.URL.indexOf("/fr") >= 0) {
      i18n.use(initReactI18next).init({lng: 'fr'});
      const action = { type: "TOGGLE_LANGUAGE", value: 'fr' }
      this.props.dispatch(action)
      this.setState({
        language: 'fr',
      })
    } else if(document.URL.indexOf("/es") >= 0) {
      i18n.use(initReactI18next).init({lng: 'es'});
      const action = { type: "TOGGLE_LANGUAGE", value: 'es' }
      this.props.dispatch(action)
      this.setState({
        language: 'es',
      })
    } else if(document.URL.indexOf("/it") >= 0) {
      i18n.use(initReactI18next).init({lng: 'it'});
      const action = { type: "TOGGLE_LANGUAGE", value: 'it' }
      this.props.dispatch(action)
      this.setState({
        language: 'it',
      })
    } else if(document.URL.indexOf("/de") >= 0) {
      i18n.use(initReactI18next).init({lng: 'de'});
      const action = { type: "TOGGLE_LANGUAGE", value: 'de' }
      this.props.dispatch(action)
      this.setState({
        language: 'de',
      })
    } else {
      i18n.use(initReactI18next).init({lng: 'en'});
      const action = { type: "TOGGLE_LANGUAGE", value: 'en' }
      this.props.dispatch(action)
      this.setState({
        language: 'en',
      })
    }
  }

  loadBase() {
    getBase(window.location.hostname).then(data => {
      const action = { type: "TOGGLE_BASE", value: data }
      this.props.dispatch(action)
      this.setState({
        base: data,
        isLoading: false,
      })
    })
  }

  componentDidMount(){
    this.detectLang();
    this.loadBase();
  }

  render() {
    if (this.state.isLoading) {
      return null
    } else {
      return (
        <WebfontLoader config={config} onStatus={callback}>
          <ThemeProvider theme={theme(this.state.base.theme)}>
            <CssBaseline />
            <I18nextProvider i18n={i18n}>
              <Router>
                <Switch>
                  <Route
                    path="/:lang/"
                    render={props =>
                      <Language theme={this.state.base} lang={this.state.language} {...props} />
                    }
                  />
                </Switch>
              </Router>
            </I18nextProvider>
          </ThemeProvider>
        </WebfontLoader>
      )
    }
  }
}

const styles = {
  typography: {
    fontFamily: 'Titillium Web',
  }
};

const mapStateToProps = function(state) {
  return {
    language: state.paramsReducer.language,
    content: state.paramsReducer.content,
    base: state.paramsReducer.base,
    cart: state.accountReducer.cart,
    cartdetail: state.accountReducer.cartdetail,
  }
}

export default connect(mapStateToProps)(withStyles(styles)(App));
