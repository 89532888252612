const initialState = { logged: false, details: [], shipping: [], payment: [], projects: [], cart: [], cartdetail: [] }

function account(state = initialState, action) {
  switch (action.type) {
    case 'TOGGLE_LOGGED':
      state.logged = action.value
      return state
    case 'TOGGLE_DETAILS':
      state.details = action.value
      return state
    case 'TOGGLE_SHIPPING':
      state.shipping = action.value
      return { ...state, state }
    case 'TOGGLE_PAYMENT':
      state.payment = action.value
      return { ...state, state }
    case 'TOGGLE_PROJECTS':
      state.projects = action.value
      return state
    case 'TOGGLE_CART':
      state.cart = action.value
      return { ...state, state }
    case 'TOGGLE_CART_DETAIL':
      state.cartdetail = action.value
      return { ...state, state }
    default:
      return state
  }
}

export default account
