import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import MetaTags from 'react-meta-tags';
import { Translation } from 'react-i18next';
import styles from '../styles/app.module.css';
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext } from 'pure-react-carousel';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import imageindex from '../Svg/index.svg';
import Stepper from '../Components/Stepper';
import TextField from '@material-ui/core/TextField';
import InputBase from '@material-ui/core/InputBase';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Button from '@material-ui/core/Button';
import { HeadIcon, ShoppingcartIcon, CircleIcon } from '../Svg/Icons';
import { checkEmailApi, getLoginDetail, sendEmailApi } from '../Api/RestApi'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
  useHistory,
  useLocation
} from "react-router-dom";

const drawerWidth = 340;

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    maxWidth: '100vw'
  },
  account_content: {
    margin: theme.spacing(4),
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(5),
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
    backgroundColor: "#fff",
    [theme.breakpoints.down('xs')]: {
       margin: theme.spacing(1),
       padding: theme.spacing(2),
    },
  },
  paper: {
    padding: theme.spacing(4),
    [theme.breakpoints.down('xs')]: {
       paddingLeft: theme.spacing(2),
       paddingRight: theme.spacing(2),
    },
  },
  container: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-start',
    flexWrap: 'nowrap',
    height: '35px',
    marginBottom: theme.spacing(7),
  },
  buttonSvg: {
    fontSize: '20px',
    fill: theme.palette.primary.main,
    stroke: theme.palette.primary.main,
  },
  buttonSvg1: {
    fontSize: '35px',
    fill: theme.palette.primary.main,
    stroke: theme.palette.primary.main,
  },
  buttonSvg2: {
    fontSize: '30px',
    fill: 'none',
    stroke: theme.palette.primary.main,
    marginBottom: '5px',
  },
  class: {
    fontSize: '13px',
    marginLeft: theme.spacing(2),
    lineHeight: '20px',
    marginBottom: '3px',
    fontWeight: theme.typography.fontWeightBold,
    textTransform: 'uppercase',
    letterSpacing: '2px'
  },
  legal: {
    fontSize: '13px',
    lineHeight: '20px',
    marginTop: theme.spacing(4),
    fontWeight: theme.typography.fontWeightLight,
    textTransform: 'uppercase',
    letterSpacing: '2px'
  },
  boxhead: {
    marginBottom: theme.spacing(4),
  },
  head: {
    fontSize: '13px',
    fontWeight: theme.typography.fontWeightBold,
    textTransform: 'uppercase',
    letterSpacing: '2px',
    marginBottom: theme.spacing(1),
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'space-between'
  },
  form: {
    borderRadius: '30px',
    marginTop: theme.spacing(3),
    backgroundColor: theme.palette.info.main,
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    height: '60px',
  },
  circle: {
    width: '60px',
    height: '60px',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: 'auto',
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(3),
  },
  buttonLogin: {
    fontSize: '26px',
    fill: theme.palette.navtextcolor.main,
    stroke: theme.palette.navtextcolor.main,
  },
  buttonLogin2: {
    fontSize: '26px',
    fill: theme.palette.grey.A700,
    stroke: theme.palette.grey.A700,
  },
  error: {
    fontSize: '13px',
    fontWeight: theme.typography.fontWeightNormal,
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(3),
    color: theme.palette.error.main,
  },
  success: {
    fontSize: '13px',
    fontWeight: theme.typography.fontWeightNormal,
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(3),
    color: theme.palette.success.main,
  },
}));

function Login(props) {
  const classes = useStyles();
  const { language, translations, logged } = props
  let history = useHistory();

  const [locale, setLocale] = React.useState(language);

  const [values, setValues] = React.useState({
    emailCreate: '',
    emailCreateExist: false,
    emailCreateError: false,
  });

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value, emailCreateExist: false, emailCreateError: false });
  };


  const createAction = (event) => {
    let isValid = true;
    let isErrorEmail = false;
    let isEmailExist = false;
    if (typeof values.emailCreate !== "undefined") {
      var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
      if (!pattern.test(values.emailCreate)) {
        isValid = false;
        setValues({ ...values, emailCreateError: true });
      }
    }

    if (isValid) {
      sendEmailApi(values.emailCreate, language, window.location.hostname).then(data => {
        if (data.exist) {
          console.log(data)
          setValues({ ...values, emailCreateExist: true });
        } else {
          setValues({ ...values, emailCreateError: true });
        }
      })
    }
  }

  useEffect(()=>{
    window.scrollTo(0, 0);
  }, [])

    return (
      <div>
        <MetaTags>
          <title>{translations["link_password"]}</title>
          <meta name="description" content="Some description." />
          <meta property="og:title" content={translations["link_password"]} />
          <meta property="og:image" content="path/to/image.jpg" />
        </MetaTags>
        <div className={classes.account_content}>
          <div className={classes.root}>
            <Grid container spacing={5}>
              <Grid item xs={12}>
                <Box border={1} borderColor="grey.200" borderRadius={5} className={classes.paper}>
                  <div className={classes.container}>
                    <HeadIcon className={classes.buttonSvg1} />
                    <div className={classes.class}>{translations["link_password"]}</div>
                  </div>
                  <Grid container spacing={5}>
                    <Grid item xs={12} lg={6}>
                      <Box borderBottom={1} borderColor="grey.200" className={classes.boxhead}>
                        <div className={classes.head}>
                          {translations["link_password_send"]}
                          <CircleIcon className={classes.buttonSvg} />
                        </div>
                      </Box>
                      <form className={classes.root} noValidate>
                        <InputBase
                          id="createEmail"
                          variant="filled"
                          color="secondary"
                          fullWidth
                          required
                          value={values.emailCreate}
                          onChange={handleChange('emailCreate')}
                          placeholder={translations["link_login_email"]}
                          className={classes.form}
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton aria-label="toggle password visibility" edge="end">
                                <CircleIcon className={classes.buttonSvg} />
                              </IconButton>
                            </InputAdornment>
                          }
                        />
                        {values.emailCreateError ? <FormHelperText className={classes.error}>{translations["link_login_email_error"]}</FormHelperText> : ''}
                        {values.emailCreateExist ? <FormHelperText className={classes.success}>{translations["link_password_sended"]}</FormHelperText> : ''}
                        <Button onClick={createAction} variant="outlined" disableElevation color="grey.A700" className={classes.circle}>
                          <CircleIcon className={classes.buttonLogin2} />
                        </Button>
                      </form>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </div>
          <div className={classes.legal}>{translations["link_login_legal"]}</div>
        </div>
      </div>
    )
}

const mapStateToProps = function(state) {
  return {
    language: state.paramsReducer.language,
    content: state.paramsReducer.content,
    logged: state.accountReducer.logged,
    details: state.accountReducer.details,
    base: state.paramsReducer.base,
  }
}

export default connect(mapStateToProps)(Login);
