import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import MetaTags from 'react-meta-tags';
import { Translation } from 'react-i18next';
import styles from '../styles/app.module.css';
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext } from 'pure-react-carousel';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import imageindex from '../Svg/index.svg';
import Stepper from '../Components/Stepper';
import TextField from '@material-ui/core/TextField';
import InputBase from '@material-ui/core/InputBase';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Button from '@material-ui/core/Button';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { HeadIcon, ShoppingcartIcon, CircleIcon, ShipIcon } from '../Svg/Icons';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import { checkEmailApi, checkVIES, createAccount } from '../Api/RestApi'
import parsePhoneNumber from 'libphonenumber-js'
import { isPossiblePhoneNumber, isValidPhoneNumber } from 'libphonenumber-js'
import { postcodeValidator, postcodeValidatorExistsForCountry } from 'postcode-validator';
import passwordValidator from 'password-validator'
import { isSIRET, isSIREN } from 'siret';
import {
  checkVAT,
  andorra,
  austria,
  belgium,
  bulgaria,
  croatia,
  cyprus,
  czechRepublic,
  denmark,
  estonia,
  europe,
  finland,
  france,
  germany,
  greece,
  hungary,
  ireland,
  italy,
  latvia,
  lithuania,
  luxembourg,
  malta,
  netherlands,
  norway,
  poland,
  portugal,
  romania,
  russia,
  serbia,
  slovakiaRepublic,
  slovenia,
  spain,
  sweden,
  switzerland,
  unitedKingdom
} from 'jsvat';
import {
  BrowserRouter as Router,
  Redirect,
  useHistory,
  useLocation
} from "react-router-dom";

const drawerWidth = 340;

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    maxWidth: '100vw'
  },
  header_content: {
    margin: theme.spacing(4),
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-start',
    [theme.breakpoints.down('xs')]: {
       margin: theme.spacing(1),
    },
  },
  header_class: {
    fontSize: '24px',
    margin: '0',
    padding: '0',
    lineHeight: '30px',
    marginBottom: '3px',
    fontWeight: theme.typography.fontWeightBold,
    letterSpacing: '2px',
    margin: theme.spacing(3),
    [theme.breakpoints.down('xs')]: {
       margin: theme.spacing(1),
       fontSize: '16px',
       lineHeight: '22px',
    },
  },
  account_content: {
    margin: theme.spacing(4),
    padding: theme.spacing(5),
    backgroundColor: "#fff",
    [theme.breakpoints.down('xs')]: {
       margin: theme.spacing(1),
       padding: theme.spacing(2),
    },
  },
  paper: {
    padding: theme.spacing(4),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    height: '100%',
    [theme.breakpoints.down('xs')]: {
       paddingLeft: theme.spacing(2),
       paddingRight: theme.spacing(2),
    },
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexWrap: 'nowrap',
    height: '35px',
    marginBottom: theme.spacing(7),
  },
  buttonSvg: {
    fontSize: '20px',
    fill: theme.palette.primary.main,
    stroke: theme.palette.primary.main,
  },
  buttonSvg1: {
    fontSize: '35px',
    fill: theme.palette.primary.main,
    stroke: theme.palette.primary.main,
  },
  buttonSvg2: {
    fontSize: '45px',
    fill: theme.palette.primary.main,
    stroke: theme.palette.primary.main,
  },
  class: {
    fontSize: '13px',
    marginLeft: theme.spacing(2),
    lineHeight: '20px',
    marginTop: theme.spacing(1),
    fontWeight: theme.typography.fontWeightBold,
    textTransform: 'uppercase',
    letterSpacing: '2px'
  },
  legal: {
    fontSize: '13px',
    lineHeight: '20px',
    marginTop: theme.spacing(4),
    fontWeight: theme.typography.fontWeightLight,
    textTransform: 'uppercase',
    letterSpacing: '2px'
  },
  boxhead: {
    marginBottom: theme.spacing(4),
  },
  head: {
    fontSize: '13px',
    fontWeight: theme.typography.fontWeightBold,
    textTransform: 'uppercase',
    letterSpacing: '2px',
    marginBottom: theme.spacing(1),
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'space-between'
  },
  form: {
    borderRadius: '30px',
    marginTop: theme.spacing(3),
    backgroundColor: theme.palette.light.main,
    color: theme.palette.light.contrastText,
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    height: '60px',
  },
  circle: {
    width: '60px',
    height: '60px',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: 'auto',
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(3),
    backgroundColor: theme.palette.primary.main,
  },
  buttonLogin: {
    fontSize: '26px',
    fill: theme.palette.light.main,
    stroke: theme.palette.light.main,
  },
  buttonLogin2: {
    fontSize: '26px',
    fill: theme.palette.secondary.main,
    stroke: theme.palette.secondary.main,
  },
  error: {
    fontSize: '13px',
    fontWeight: theme.typography.fontWeightNormal,
    paddingLeft: theme.spacing(3),
    color: theme.palette.error.main,
  },
  switch: {
    fontSize: '13px',
    margin: theme.spacing(1),
    lineHeight: '20px',
    fontWeight: theme.typography.fontWeightRegular,
    textTransform: 'uppercase',
    letterSpacing: '2px'
  },
  switchlabel: {
    fontSize: '15px',
    margin: theme.spacing(1),
    lineHeight: '20px',
    fontWeight: theme.typography.fontWeightRegular,
    letterSpacing: '1px'
  },
  switchMargin: {
    height: '60px',
    marginTop: theme.spacing(3),
  },
  legal_container: {
    marginTop: theme.spacing(7),
  },
  success: {
    color: theme.palette.success.main,
  }
}));

function Login(props) {
  const classes = useStyles();
  const { language, translations, logged, countries, data } = props
  let history = useHistory();

  const [locale, setLocale] = React.useState(language);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  let startEmail = false

  let get_email = (new URLSearchParams(window.location.search)).get("email")
  if (get_email) startEmail = true

  const [values, setValues] = React.useState({
    password: '',
    validPassword: false,
    passwordError: false,
    passwordConfirm: '',
    validPasswordConfirm: false,
    passwordConfirmError: false,
    emailCreate: get_email,
    emailError: false,
    emailCreateExist: false,
    emailCreateError: false,
    validEmailCreate: get_email,
    showPassword: false,
    phone: '',
    validPhone: false,
    phoneIntl: '',
    isCompany: true,
    company: '',
    validCompany: false,
    companyError: false,
    newsletter: true,
    firstname: '',
    validFirstname: false,
    firstnameError: false,
    lastname: '',
    validLastname: false,
    lastnameError: false,
    address1: '',
    validAddress1: false,
    address1Error: false,
    address2: '',
    validAddress2: false,
    address2Error: false,
    postcode: '',
    validPostcode: false,
    postcodeError: false,
    city: '',
    validCity: false,
    cityError: false,
    vat: '',
    vatError: false,
    siret: '',
    siretError: false,
    validSiret: false,
    needVAT: false,
    validVAT: false,
    needSiret: false,
    country: language.toUpperCase(),
  });

  const countriesList: ReadonlyArray<Country> = [
    andorra,
    austria,
    belgium,
    bulgaria,
    croatia,
    cyprus,
    czechRepublic,
    denmark,
    estonia,
    europe,
    finland,
    france,
    germany,
    greece,
    hungary,
    ireland,
    italy,
    latvia,
    lithuania,
    luxembourg,
    malta,
    netherlands,
    norway,
    poland,
    portugal,
    romania,
    russia,
    serbia,
    slovakiaRepublic,
    slovenia,
    spain,
    sweden,
    switzerland,
    unitedKingdom
  ];

  const handleChange = (prop, error, valid, min, max) => (event) => {
    var isValid = false;
    if ((event.target.value.length >= min) && (event.target.value.length <= max)) {
      isValid = true;
    }
    setValues({ ...values, [prop]: event.target.value, [valid]: isValid, [error]: false });
  };

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleChangeNewsletter = () => {
    setValues({ ...values, newsletter: !values.newsletter });
  };

  const handleChangeCompany = () => {
    handleChangeLegal(values.country, !values.isCompany)
  };

  const handleChangeCountry = (prop) => (event) => {
    handleChangeLegal(event.target.value, values.isCompany)
  };

  const handleChangeLegal = (country, isCompany) => {
    let postcode = values.postcode
    let validPostcode = values.validPostcode
    if (country != values.country) {
      postcode = ''
      validPostcode = false
    }
    if (isCompany) {
      var result = countries.find(obj => {
        return obj.code === country
      })
      if (result) {
        setValues({ ...values, needVAT: result.needvat, needSiret: result.needsiret, country: country, isCompany: isCompany, companyError: false, postcode: postcode, validPostcode: validPostcode });
      }
    } else {
      setValues({ ...values, needVAT: false, needSiret: false, country: country, isCompany: isCompany, companyError: false, postcode: postcode, validPostcode: validPostcode });
    }
  };

  const handleChangeVat = (prop) => (event) => {
    let vatStatus = false;
    var okVAT = checkVAT(event.target.value, countriesList)
    setValues({ ...values, [prop]: event.target.value, validVAT: okVAT.isValid, vatError: false });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleChangeEmail = (prop) => (event) => {
    let isValid = true;
    let isErrorEmail = false;
    let isEmailExist = false;
    if (typeof event.target.value !== "undefined") {
      var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
      if (!pattern.test(event.target.value)) {
        isValid = false;
        isErrorEmail = true;
      }
    }
    if (isValid) {
      checkEmailApi(event.target.value, window.location.hostname).then(data => {
        if (data.exist) {
          isValid = false;
          isEmailExist = true;
        }
      })
    }
    setValues({ ...values, [prop]: event.target.value, emailCreateError: isErrorEmail, emailCreateExist: isEmailExist, validEmailCreate: isValid, emailError: false });
  }

  const handleChangePhone = (prop) => (event) => {
    let isValid = false;
    let phoneIntl = '';
    let phoneNumber = parsePhoneNumber(event.target.value, values.country)
    if (phoneNumber) {
      isValid = isValidPhoneNumber(phoneNumber.nationalNumber, phoneNumber.country)
      if (isValid) {
        phoneIntl = phoneNumber.number
        console.log(phoneNumber)
      }
    }
    setValues({ ...values, [prop]: event.target.value, validPhone: isValid, phoneIntl: phoneIntl, phoneError: false});
  }

  const handleChangePostcode = (prop) => (event) => {
    let postcode = postcodeValidator(event.target.value, values.country)
    setValues({ ...values, [prop]: event.target.value, validPostcode: postcode, postcodeError: false});
  }

  const handleChangePassword = (prop) => (event) => {
    var schema = new passwordValidator();
    schema
    .is().min(6)
    .is().max(10)
    .has().uppercase()
    .has().lowercase()
    .has().digits(1)
    .has().not().spaces();
    var isValid = schema.validate(event.target.value);
    setValues({ ...values, [prop]: event.target.value, validPassword: isValid, passwordError: false});
  }

  const handleChangePasswordConfirm = (prop) => (event) => {
    var isValid = false;
    if (event.target.value === values.password) {
      isValid = true;
    }
    setValues({ ...values, [prop]: event.target.value, validPasswordConfirm: isValid, passwordConfirmError: false});
  }

  const handleChangeSiret = (prop) => (event) => {
    var isValid = isSIRET(event.target.value);
    setValues({ ...values, [prop]: event.target.value, validSiret: isValid, siretError: false});
  }

  const createAction = (event) => {
    let isValid = true;
    let emailError = false;
    let passwordError = false;
    let passwordConfirmError = false;
    let phoneError = false;
    let companyError = false;
    let firstnameError = false;
    let lastnameError = false;
    let address1Error = false;
    let address2Error = false;
    let postcodeError = false;
    let cityError = false;
    let siretError = false;
    let vatError = false;

    if ((!values.validEmailCreate) ||
        (!values.validPassword) ||
        (!values.validPasswordConfirm) ||
        (!values.validPhone) ||
        ((values.isCompany) && (!values.validCompany)) ||
        (!values.validFirstname) ||
        (!values.validLastname) ||
        (!values.validAddress1) ||
        (!values.validPostcode) ||
        (!values.validCity) ||
        ((values.isCompany) && (values.needVAT) && (!values.validVAT))  ||
        ((values.isCompany) && (values.needSiret) && (!values.validSiret))
    ){
          isValid = false;
    }

    if (!isValid) {
      if (!values.validEmailCreate) {
        emailError = true
      }
      if (!values.validPassword) {
        passwordError = true
      }
      if (!values.validPasswordConfirm) {
        passwordConfirmError = true
      }
      if (!values.validPhone) {
        phoneError = true
      }
      if ((values.isCompany) && (!values.validCompany)) {
        companyError = true
      }
      if (!values.validFirstname) {
        firstnameError = true
      }
      if (!values.validLastname) {
        lastnameError = true
      }
      if (!values.validAddress1) {
        address1Error = true
      }
      if (!values.validAddress2) {
        address2Error = true
      }
      if (!values.validPostcode) {
        postcodeError = true
      }
      if (!values.validCity) {
        cityError = true
      }
      if ((values.isCompany) && (values.needVAT) && (!values.validVAT)) {
        vatError = true
      }
      if ((values.isCompany) && (values.needSiret) && (!values.validSiret)) {
        siretError = true
      }
    }

    setValues({ ...values,
      emailError: emailError,
      passwordError: passwordError,
      passwordConfirmError: passwordConfirmError,
      phoneError: phoneError,
      companyError: companyError,
      firstnameError: firstnameError,
      lastnameError: lastnameError,
      address1Error: address1Error,
      address2Error: address2Error,
      postcodeError: postcodeError,
      cityError: cityError,
      vatError: vatError,
      siretError: siretError
    });

    const valueCreate = {
      'domain': window.location.hostname,
      'password':values.password,
      'email':values.emailCreate,
      'phone':values.phone,
      'isCompany':values.isCompany,
      'company':values.company,
      'newsletter':values.newsletter,
      'firstname':values.firstname,
      'lastname':values.lastname,
      'address1':values.address1,
      'address2':values.address2,
      'postcode':values.postcode,
      'city':values.city,
      'vat':values.vat,
      'siret':values.siret,
      'country':values.country,
      'language': language
    }

    if (isValid) {
      createAccount(
        valueCreate
      ).then(data => {
        if (data.success) {
          const action1 = { type: "TOGGLE_LOGGED", value: true }
          props.dispatch(action1)
          const action2 = { type: "TOGGLE_DETAILS", value: data }
          props.dispatch(action2)
          history.push('/' + language + '/' + translations["link_account_url"])
        } else {
          setValues({ ...values, emailCreateExist: true});
        }
      })
    }
  }

  useEffect(()=>{
    handleChangeLegal(values.country, values.isCompany)
  }, [])

  if (logged) {
    return (
      <Redirect to={{ pathname: "/" + language  + "/" + translations["link_account_url"] }} />
    )
  } else {
    return (
      <div>
        <MetaTags>
          <title>{data.blocks['seo_create_account'].title }</title>
          <meta name="description" content={data.blocks['seo_create_account'].text } />
          <meta property="og:title" content={data.blocks['seo_create_account'].title } />
        </MetaTags>
        <div className={classes.header_content}>
          <HeadIcon className={classes.buttonSvg1} />
          <h1 className={classes.header_class}>{translations["link_create_account"]}</h1>
        </div>
        <div className={classes.account_content}>
          <div className={classes.root}>
            <form className={classes.root} noValidate>
              <Grid container spacing={5}>
                <Grid item xs={12} lg={4}>
                  <Box border={1} borderColor="grey.200" borderRadius={5} className={classes.paper}>
                    <div className={classes.container}>
                      <HeadIcon className={classes.buttonSvg1} />
                      <div className={classes.class}>{translations["link_create_account_informations"]}</div>
                    </div>
                    <InputBase
                      id="emailCreate"
                      variant="filled"
                      color="secondary"
                      fullWidth
                      required
                      value={values.emailCreate}
                      onChange={handleChangeEmail('emailCreate')}
                      placeholder={translations["link_login_email"]}
                      className={classes.form}
                      inputProps={{
                        'aria-label': 'emailCreate',
                      }}
                      endAdornment={
                        <InputAdornment position="end">
                          {values.validEmailCreate ? <CheckIcon className={classes.success} /> : ''}
                        </InputAdornment>
                      }
                    />
                    {values.emailError ? <FormHelperText className={classes.error}>{translations["link_login_email_error"]}</FormHelperText> : ''}
                    {values.emailCreateExist ? <FormHelperText className={classes.error}>{translations["link_account_create_email_exist"]}</FormHelperText> : ''}
                    <InputBase
                      id="loginPassword"
                      variant="filled"
                      color="secondary"
                      type={values.showPassword ? 'text' : 'password'}
                      fullWidth
                      required
                      value={values.password}
                      onChange={handleChangePassword('password')}
                      placeholder={translations["link_create_account_password"]}
                      className={classes.form}
                      endAdornment={
                        <InputAdornment position="end">
                          {values.validPassword ? <CheckIcon className={classes.success} /> : ''}
                        </InputAdornment>
                      }
                    />
                    {values.passwordError ? <FormHelperText className={classes.error}>{translations["link_account_create_password_error"]}</FormHelperText> : ''}
                    <InputBase
                      id="loginPasswordConfirm"
                      variant="filled"
                      color="secondary"
                      type={values.showPassword ? 'text' : 'password'}
                      fullWidth
                      required
                      value={values.passwordConfirm}
                      onChange={handleChangePasswordConfirm('passwordConfirm')}
                      placeholder={translations["link_create_account_password_confirm"]}
                      className={classes.form}
                      endAdornment={
                        <InputAdornment position="end">
                          {values.validPasswordConfirm ? <CheckIcon className={classes.success} /> : ''}
                        </InputAdornment>
                      }
                    />
                    {values.passwordConfirmError ? <FormHelperText className={classes.error}>{translations["link_account_create_password_confirm_error"]}</FormHelperText> : ''}
                    <InputBase
                      id="loginPhone"
                      variant="filled"
                      color="secondary"
                      fullWidth
                      required
                      value={values.phone}
                      onChange={handleChangePhone('phone')}
                      placeholder={translations["link_create_account_phone"]}
                      className={classes.form}
                      endAdornment={
                        <InputAdornment position="end">
                          {values.validPhone ? <CheckIcon className={classes.success} /> : ''}
                        </InputAdornment>
                      }
                    />
                    {values.phoneError ? <FormHelperText className={classes.error}>{translations["link_account_create_phone_error"]}</FormHelperText> : ''}
                    <Grid component="label" className={classes.switchMargin} container alignItems="left" spacing={1}>
                      <Grid item className={classes.switchlabel}>{translations["link_create_account_newsletter"]}</Grid>
                      <Grid item>
                        <Switch
                          checked={values.newsletter}
                          onChange={handleChangeNewsletter}
                          name="checkedB"
                          color="primary"
                        />
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
                <Grid item xs={12} lg={8}>
                  <Box border={1} borderColor="grey.200" borderRadius={5} className={classes.paper}>
                    <Grid container spacing={0}>
                      <Grid item xs={12} lg={6}>
                        <div className={classes.container}>
                          <ShipIcon className={classes.buttonSvg2} />
                          <div className={classes.class}>{translations["link_create_account_address"]}</div>
                        </div>
                      </Grid>
                      <Grid item xs={12} lg={6}>
                        <Grid component="label" container alignItems="center" spacing={1}>
                          <Grid item className={classes.switch}>{translations["link_create_account_isPart"]}</Grid>
                          <Grid item>
                            <Switch
                              checked={values.isCompany}
                              onChange={handleChangeCompany}
                              name="checkedB"
                              color="primary"
                            />
                          </Grid>
                          <Grid item className={classes.switch}>{translations["link_create_account_isCompany"]}</Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid container spacing={5}>
                      <Grid item xs={12} lg={6}>
                        {values.isCompany ?
                          <InputBase
                            id="company"
                            variant="filled"
                            color="secondary"
                            fullWidth
                            required
                            value={values.company}
                            onChange={handleChange('company', 'companyError', 'validCompany', '2', '30')}
                            placeholder={translations["link_create_account_company"]}
                            className={classes.form}
                            inputProps={{
                              'aria-label': 'company',
                            }}
                            endAdornment={
                              <InputAdornment position="end">
                                {values.validCompany ? <CheckIcon className={classes.success} /> : ''}
                              </InputAdornment>
                            }
                          />
                        : ''}
                        {values.companyError ? <FormHelperText className={classes.error}>{translations["link_account_create_length_error"]}</FormHelperText> : ''}
                        <InputBase
                          id="firstname"
                          variant="filled"
                          color="secondary"
                          fullWidth
                          required
                          value={values.firstname}
                          onChange={handleChange('firstname', 'firstnameError', 'validFirstname', '2', '30')}
                          placeholder={translations["link_create_account_firstname"]}
                          className={classes.form}
                          inputProps={{
                            'aria-label': 'emailLogin',
                          }}
                          endAdornment={
                            <InputAdornment position="end">
                              {values.validFirstname ? <CheckIcon className={classes.success} /> : ''}
                            </InputAdornment>
                          }
                        />
                        {values.firstnameError ? <FormHelperText className={classes.error}>{translations["link_account_create_length_error"]}</FormHelperText> : ''}
                        <InputBase
                          id="lastname"
                          variant="filled"
                          color="secondary"
                          fullWidth
                          required
                          value={values.lastname}
                          onChange={handleChange('lastname', 'lastnameError', 'validLastname', '2', '30')}
                          placeholder={translations["link_create_account_lastname"]}
                          className={classes.form}
                          inputProps={{
                            'aria-label': 'lastname',
                          }}
                          endAdornment={
                            <InputAdornment position="end">
                              {values.validLastname ? <CheckIcon className={classes.success} /> : ''}
                            </InputAdornment>
                          }
                        />
                        {values.lastnameError ? <FormHelperText className={classes.error}>{translations["link_account_create_length_error"]}</FormHelperText> : ''}
                      </Grid>
                      <Grid item xs={12} lg={6}>
                        <InputBase
                          id="address1"
                          variant="filled"
                          color="secondary"
                          fullWidth
                          required
                          value={values.address1}
                          onChange={handleChange('address1', 'address1Error', 'validAddress1', '2', '30')}
                          placeholder={translations["link_create_account_address1"]}
                          className={classes.form}
                          inputProps={{
                            'aria-label': 'emailLogin',
                          }}
                          endAdornment={
                            <InputAdornment position="end">
                              {values.validAddress1 ? <CheckIcon className={classes.success} /> : ''}
                            </InputAdornment>
                          }
                        />
                        {values.address1Error ? <FormHelperText className={classes.error}>{translations["link_account_create_length_error"]}</FormHelperText> : ''}
                        <InputBase
                          id="address2"
                          variant="filled"
                          color="secondary"
                          fullWidth
                          required
                          value={values.address2}
                          onChange={handleChange('address2', 'address2Error', 'validAddress2', '2', '30')}
                          placeholder={translations["link_create_account_address2"]}
                          className={classes.form}
                          inputProps={{
                            'aria-label': 'emailLogin',
                          }}
                          endAdornment={
                            <InputAdornment position="end">
                              {values.validAddress2 ? <CheckIcon className={classes.success} /> : ''}
                            </InputAdornment>
                          }
                        />
                        <InputBase
                          id="postcode"
                          variant="filled"
                          color="secondary"
                          fullWidth
                          required
                          value={values.postcode}
                          onChange={handleChangePostcode('postcode')}
                          placeholder={translations["link_create_account_postcode"]}
                          className={classes.form}
                          inputProps={{
                            'aria-label': 'emailLogin',
                          }}
                          endAdornment={
                            <InputAdornment position="end">
                              {values.validPostcode ? <CheckIcon className={classes.success} /> : ''}
                            </InputAdornment>
                          }
                        />
                        {values.postcodeError ? <FormHelperText className={classes.error}>{translations["link_account_create_postcode_error"]}</FormHelperText> : ''}
                        <InputBase
                          id="city"
                          variant="filled"
                          color="secondary"
                          fullWidth
                          required
                          value={values.city}
                          onChange={handleChange('city', 'cityError', 'validCity', '2', '30')}
                          placeholder={translations["link_create_account_city"]}
                          className={classes.form}
                          inputProps={{
                            'aria-label': 'emailLogin',
                          }}
                          endAdornment={
                            <InputAdornment position="end">
                              {values.validCity ? <CheckIcon className={classes.success} /> : ''}
                            </InputAdornment>
                          }
                        />
                        {values.cityError ? <FormHelperText className={classes.error}>{translations["link_account_create_length_error"]}</FormHelperText> : ''}
                        <Select
                          labelId="demo-simple-select-label"
                          variant="standard"
                          color="secondary"
                          fullWidth
                          id="demo-simple-select"
                          value={values.country}
                          onChange={handleChangeCountry('country')}
                          placeholder={translations["link_create_account_siret"]}
                          className={classes.form}
                        >
                        {countries.map(({ code, title }, index) => (
                          <MenuItem value={code}>{title}</MenuItem>
                        ))}
                        </Select>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
              </Grid>
              {values.needVAT || values.needSiret ?
              <div className={classes.legal_container}>
                <Box border={1} borderColor="grey.200" borderRadius={5} className={classes.paper}>
                  <div className={classes.container}>
                    <HeadIcon className={classes.buttonSvg1} />
                    <div className={classes.class}>{translations["link_create_account_legals"]}</div>
                  </div>
                  <Grid container spacing={5}>
                    {values.needSiret ?
                    <Grid item xs={12} lg={6}>
                        <InputBase
                          id="siret"
                          variant="filled"
                          color="secondary"
                          fullWidth
                          required
                          value={values.siret}
                          onChange={handleChangeSiret('siret')}
                          placeholder={translations["link_create_account_siret"]}
                          className={classes.form}
                          inputProps={{
                            'aria-label': 'siret',
                          }}
                          endAdornment={
                            <InputAdornment position="end">
                              {values.validSiret ? <CheckIcon className={classes.success} /> : ''}
                            </InputAdornment>
                          }
                        />
                        {values.siretError ? <FormHelperText className={classes.error}>{translations["link_account_create_siret_error"]}</FormHelperText> : ''}
                    </Grid>
                    : ''}
                    {values.needVAT ?
                    <Grid item xs={12} lg={6}>
                        <InputBase
                          id="vat"
                          variant="filled"
                          color="secondary"
                          fullWidth
                          required
                          value={values.vat}
                          onChange={handleChangeVat('vat')}
                          placeholder={translations["link_create_account_vat"]}
                          className={classes.form}
                          inputProps={{
                            'aria-label': 'vatnumber',
                          }}
                          endAdornment={
                            <InputAdornment position="end">
                              {values.validVAT ? <CheckIcon className={classes.success} /> : ''}
                            </InputAdornment>
                          }
                        />
                        {values.vatError ? <FormHelperText className={classes.error}>{translations["link_account_create_vat_error"]}</FormHelperText> : ''}
                    </Grid>
                    : ''}
                  </Grid>
                </Box>
              </div>
              : ''}
              <Button onClick={createAction} variant="contained" disableElevation color="primary" className={classes.circle}>
                <CircleIcon className={classes.buttonLogin} />
              </Button>
            </form>
          </div>
          <div className={classes.legal}>{translations["link_login_legal"]}</div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = function(state) {
  return {
    language: state.paramsReducer.language,
    content: state.paramsReducer.content,
    logged: state.accountReducer.logged,
    details: state.accountReducer.details,
    base: state.paramsReducer.base,
  }
}

export default connect(mapStateToProps)(Login);
