import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { HeadIcon, ShoppingcartIcon, TrashIcon } from '../Svg/Icons';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import NumberFormat from 'react-number-format';
import { getTotal } from '../Api/RestApi'
import {
  Link
} from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: '100vw',
    [theme.breakpoints.down('sm')]: {
       overflowX: 'scroll',
       paddingLeft: '0',
       paddingRight: '0'
    },
  },
  cart_nb: {
    fontSize: '20px',
    marginRight: theme.spacing(2),
    marginTop: theme.spacing(4),
    marginBottom: 0,
    textAlign: 'right',
    padding: '0',
    lineHeight: '20px',
    textTransform: 'uppercase',
    letterSpacing: '2px',
  },
  cart_total: {
    fontSize: '16px',
    marginRight: theme.spacing(2),
    marginTop: theme.spacing(1),
    textAlign: 'right',
    padding: '0',
    lineHeight: '20px',
    textTransform: 'uppercase',
    letterSpacing: '2px',
  },
  buttonSvg1: {
    fontSize: '40px',
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(4),
    fill: theme.palette.primary.main,
    stroke: theme.palette.primary.main,
  },
  link: {
    textDecoration: 'none',
    color: theme.palette.primary.contrastText,
    textTransform: 'uppercase',
    fontWeight: theme.typography.fontWeightLight,
  },
}));


function HeaderCart(props) {
  const { language, translations, logged, details, cart, cartdetail } = props
  const classes = useStyles();

  const [locale, setLocale] = React.useState(language);

  const [values, setValues] = React.useState({
    isLoaded: false
  });

  const handleTotal = () => {
    getTotal(details.id, details.email, window.location.hostname).then(data1 => {
      const action1 = { type: "TOGGLE_CART", value: data1 }
      props.dispatch(action1)
      setValues({
        ...values,
        isLoaded: true
      });
    });
  }

  useEffect(()=>{
    handleTotal()
  }, [])

  if (values.isLoaded && logged) {
    return (
      <Link className={classes.link} to={'/' + locale + '/' + translations["link_shoppingcart_url"]}>
        <Grid container justify="flex-end" alignItems="center" alignContent="center">
          <Grid item justify="flex-end" alignItems="flex-end" alignContent="flex-end">
            <h2 className={classes.cart_nb}>{props.cart.count} {translations["cart_quantity"]}</h2>
            <h3 className={classes.cart_total}><NumberFormat value={props.cart.sumht} displayType={'text'} thousandSeparator={false} decimalScale={2} suffix={'€'} /></h3>
          </Grid>
          <Grid item>
            <ShoppingcartIcon className={classes.buttonSvg1} />
          </Grid>
        </Grid>
      </Link>
    );
  } else {
    return null;
  }
}

const mapStateToProps = function(state) {
  return {
    language: state.paramsReducer.language,
    content: state.paramsReducer.content,
    logged: state.accountReducer.logged,
    details: state.accountReducer.details,
    cart: state.accountReducer.cart,
    cartdetail: state.accountReducer.cartdetail,
    base: state.paramsReducer.base,
  }
}

export default connect(mapStateToProps)(HeaderCart);
