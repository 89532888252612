import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import MetaTags from 'react-meta-tags';
import { Translation } from 'react-i18next';
import styles from '../styles/app.module.css';
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext } from 'pure-react-carousel';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import imageindex from '../Svg/index.svg';
import Stepper from '../Components/Stepper';
import HeaderCart from '../Components/HeaderCart';
import TextField from '@material-ui/core/TextField';
import InputBase from '@material-ui/core/InputBase';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Button from '@material-ui/core/Button';
import { HeadIcon, ShoppingcartIcon, CircleIcon, CrayonIcon, SaveIcon } from '../Svg/Icons';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import { checkEmailApi, getLoginDetail, getProjects } from '../Api/RestApi'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
  useHistory,
  useLocation
} from "react-router-dom";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

const drawerWidth = 340;

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    maxWidth: '100vw'
  },
  account_content: {
    margin: theme.spacing(4),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(5),
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
    backgroundColor: "#fff",
    [theme.breakpoints.down('xs')]: {
       margin: theme.spacing(1),
       padding: theme.spacing(2),
    },
  },
  header_content: {
    margin: theme.spacing(4),
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-start',
    [theme.breakpoints.down('xs')]: {
       margin: theme.spacing(1),
    },
  },
  header_class: {
    fontSize: '24px',
    margin: '0',
    padding: '0',
    lineHeight: '30px',
    marginBottom: '3px',
    fontWeight: theme.typography.fontWeightBold,
    letterSpacing: '2px',
    margin: theme.spacing(3),
    [theme.breakpoints.down('xs')]: {
       margin: theme.spacing(1),
       fontSize: '16px',
       lineHeight: '22px',
    },
  },
  head_class: {
    fontSize: '11px',
    margin: '0',
    padding: '0',
    lineHeight: '20px',
    textTransform: 'uppercase',
    fontWeight: theme.typography.fontWeightRegular,
    color: theme.palette.common.white,
    borderRadius: '25px',
    height: '20px',
    width: '20px',
    textAlign: 'center',
    display: 'inline-block',
  },
  subtitle_class_a: {
    backgroundColor: theme.palette.error.main,
  },
  subtitle_class_b: {
    backgroundColor: theme.palette.success.main,
  },
  subtitle_class_c: {
    backgroundColor: theme.palette.warning.main,
  },
  buttonSvg1: {
    fontSize: '35px',
    fill: theme.palette.primary.main,
    stroke: theme.palette.primary.main,
  },
  buttonSvgInverted: {
    fontSize: '24px',
    fill: theme.palette.success.main,
    stroke: theme.palette.success.main,
  },
}));

function Project(props) {
  const classes = useStyles();
  const { language, translations, logged, details, projects } = props
  let history = useHistory();

  const [values, setValues] = React.useState({
    projects: [],
  });

  const handleProjects = () => {
    getProjects(details.id, details.email, window.location.hostname).then(data => {
      const action = { type: "TOGGLE_PROJECTS", value: data }
      props.dispatch(action)
      console.log(data)
      setValues({
        ...values, projects: data, isLoaded: true,
      });
    })
  }

  useEffect(()=>{
    window.scrollTo(0, 0);
    handleProjects()
  }, [])

  if (!logged) {
    return (
      <Redirect to={{ pathname: "/" + language  + "/" + translations["link_login_url"] }} />
    )
  } else {

    if (values.isLoaded) {
      return (
        <div>
          <MetaTags>
            <title>{translations["link_projects_title"]}</title>
            <meta name="description" content="Some description." />
            <meta property="og:title" content={translations["link_projects_title"]} />
            <meta property="og:image" content="path/to/image.jpg" />
          </MetaTags>
          <div>
            <Grid container spacing={0} justify="space-between" alignItems="center" alignContent="center">
              <Grid item xs={12} lg={5} className={classes.header_content}>
                <SaveIcon className={classes.buttonSvg1} />
                <h1 className={classes.header_class}>{translations["link_projects_title"]}</h1>
              </Grid>
              <Grid item xs={12} lg={5}>
                <HeaderCart translations={translations} />
              </Grid>
            </Grid>
          </div>
          <div className={classes.account_content}>
            <div className={classes.root}>
              <Grid container spacing={5}>
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="left">{translations["link_old_project"]}</TableCell>
                      <TableCell align="center">{translations["link_projects_matiere"]}</TableCell>
                      <TableCell align="center"><div className={classes.head_class + ' ' + classes.subtitle_class_a}>A</div> {translations["link_projects_longueur"]}</TableCell>
                      <TableCell align="center"><div className={classes.head_class + ' ' + classes.subtitle_class_b}>B</div> {translations["link_projects_largeur"]}</TableCell>
                      <TableCell align="center"><div className={classes.head_class + ' ' + classes.subtitle_class_c}>C</div> {translations["link_projects_epaisseur"]}</TableCell>
                      <TableCell align="center">{translations["link_projects_poicon"]}</TableCell>
                      <TableCell align="center">{translations["link_projects_fraissurage"]}</TableCell>
                      <TableCell align="center"></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {values.projects.map(({ id, title, epaisseur, fraisurable, largeur, longueur, matiere, percable }, index) => (
                      <TableRow key={id}>
                        <TableCell component="th" scope="row">
                          {title}
                        </TableCell>
                        <TableCell align="center">{matiere}</TableCell>
                        <TableCell align="center">{longueur} mm</TableCell>
                        <TableCell align="center">{largeur} mm</TableCell>
                        <TableCell align="center">{epaisseur} mm </TableCell>
                        <TableCell align="center">{fraisurable ? <CheckIcon className={classes.success} /> : <CloseIcon className={classes.success} />}</TableCell>
                        <TableCell align="center">{percable ? <CheckIcon className={classes.success} /> : <CloseIcon className={classes.success} />}</TableCell>
                        <TableCell align="center">
                          <Link className={classes.link} to={'/' + language + '/' + translations["link_projects_url"] + '/' + translations["link_projects_edit_url"] + '/' + id }>
                            <CrayonIcon className={classes.buttonSvgInverted} />
                          </Link>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Grid>
            </div>
          </div>
        </div>
      )
    } else {
      return null;
    }
  }
}

const mapStateToProps = function(state) {
  return {
    language: state.paramsReducer.language,
    content: state.paramsReducer.content,
    logged: state.accountReducer.logged,
    details: state.accountReducer.details,
    projects: state.accountReducer.projects,
    base: state.paramsReducer.base,
  }
}

export default connect(mapStateToProps)(Project);
