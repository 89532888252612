import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: '100vw',
    [theme.breakpoints.down('sm')]: {
       overflowX: 'scroll',
       paddingLeft: '0',
       paddingRight: '0'
    },
  },
}));

function getSteps() {
  return ['1', '2', '3', '4'];
}

export default function HorizontalLabelPositionBelowStepper(props) {
  const { translations, step } = props
  const classes = useStyles();
  const activeStep = step;
  const steps = getSteps();

  return (
      <Stepper activeStep={activeStep} alternativeLabel className={classes.root}>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{translations["stepper_" + label]}</StepLabel>
          </Step>
        ))}
      </Stepper>
  );
}
