const API_TOKEN = "327ceaf2-5aa7-470a-acf3-269a7d374d2e";
const DOMAIN = 'cyrilc.com';
const PROJECT = 'www';

export function getBase(host) {
  const url = 'https://api.pinet-industrie.com/react/base?language=en&host=' + host
  return fetch(url)
    .then((response) => response.json())
    .catch((error) => console.error(error))
}

export function getParams(language, host) {
  const url = 'https://api.pinet-industrie.com/react/categories?language=' + language + '&host=' + host
  return fetch(url)
    .then((response) => response.json())
    .catch((error) => console.error(error))
}

export function getLoginDetail(email, password, host) {
  const url = 'https://api.pinet-industrie.com/react/user/check?api_key=' + API_TOKEN + '&host=' + host
  return fetch(url, {
      method: 'POST',
      body: JSON.stringify({ email: email, password: password }),
    })
    .then((response) => response.json())
    .catch((error) => console.error(error))
}

export function getUserAdress(id, email, language, host) {
  const url = 'https://api.pinet-industrie.com/react/user/user_adress?api_key=' + API_TOKEN + '&host=' + host
  return fetch(url, {
      method: 'POST',
      body: JSON.stringify({ id: id, email: email, lang: language }),
    })
    .then((response) => response.json())
    .catch((error) => console.error(error))
}

export function deleteUserAdress(id, email, address, language, host) {
  const url = 'https://api.pinet-industrie.com/react/user/delete_adress?api_key=' + API_TOKEN + '&host=' + host
  return fetch(url, {
      method: 'POST',
      body: JSON.stringify({ id: id, email: email, address: address, lang: language }),
    })
    .then((response) => response.json())
    .catch((error) => console.error(error))
}

export function editUserAdress(id, email, language, host) {
  const url = 'https://api.pinet-industrie.com/react/user/edit_adress?api_key=' + API_TOKEN + '&host=' + host
  return fetch(url, {
      method: 'POST',
      body: JSON.stringify({ id: id, email: email, lang: language }),
    })
    .then((response) => response.json())
    .catch((error) => console.error(error))
}

export function getOrderAdress(id, email, type, host) {
  const url = 'https://api.pinet-industrie.com/react/user/order_adress?api_key=' + API_TOKEN + '&host=' + host
  return fetch(url, {
      method: 'POST',
      body: JSON.stringify({ id: id, email: email, type: type }),
    })
    .then((response) => response.json())
    .catch((error) => console.error(error))
}

export function getOrders(id, email, language, host) {
  const url = 'https://api.pinet-industrie.com/react/order/list?api_key=' + API_TOKEN + '&host=' + host
  return fetch(url, {
      method: 'POST',
      body: JSON.stringify({ id: id, email: email, lang: language }),
    })
    .then((response) => response.json())
    .catch((error) => console.error(error))
}

export function getOrder(order, id, email, language, host) {
  const url = 'https://api.pinet-industrie.com/react/order/view?api_key=' + API_TOKEN + '&host=' + host
  return fetch(url, {
      method: 'POST',
      body: JSON.stringify({ order: order, id: id, email: email, lang: language }),
    })
    .then((response) => response.json())
    .catch((error) => console.error(error))
}

export function getOrderValidation(id, email, language, host) {
  const url = 'https://api.pinet-industrie.com/react/cart/cart_to_valid?api_key=' + API_TOKEN + '&host=' + host
  return fetch(url, {
      method: 'POST',
      body: JSON.stringify({ id: id, email: email, lang: language }),
    })
    .then((response) => response.json())
    .catch((error) => console.error(error))
}

export function getOrderAtos(id, token, host) {
  const url = 'https://api.pinet-industrie.com/react/cart/cart_atos?api_key=' + API_TOKEN + '&host=' + host
  return fetch(url, {
      method: 'POST',
      body: JSON.stringify({ id: id, token: token }),
    })
    .then((response) => response.json())
    .catch((error) => console.error(error))
}

export function getOrderChangeAdress(id, email, type, address, host) {
  const url = 'https://api.pinet-industrie.com/react/user/order_change_adress?api_key=' + API_TOKEN + '&host=' + host
  return fetch(url, {
      method: 'POST',
      body: JSON.stringify({ id: id, email: email, type: type, address: address }),
    })
    .then((response) => response.json())
    .catch((error) => console.error(error))
}

export function sendEmailApi(email, language, host) {
  const url = 'https://api.pinet-industrie.com/react/user/reset_password?api_key=' + API_TOKEN + '&host=' + host
  return fetch(url, {
      method: 'POST',
      body: JSON.stringify({ email: email, lang: language }),
    })
    .then((response) => response.json())
    .catch((error) => console.error(error))
}

export function checkEmailApi(email, host) {
  const url = 'https://api.pinet-industrie.com/react/user/check_email?api_key=' + API_TOKEN + '&host=' + host
  return fetch(url, {
      method: 'POST',
      body: JSON.stringify({ email: email }),
    })
    .then((response) => response.json())
    .catch((error) => console.error(error))
}

export function checkVIES(country, vatNumber) {
  const url = 'https://api.pinet-industrie.com/react/vatnumber?country='+country+'&vatnumber='+vatNumber
  return fetch(url)
    .then((response) => response.json())
    .catch((error) => console.error(error))
}

export function createAccount(values) {
  const url = 'https://api.pinet-industrie.com/react/user/create_user?api_key=' + API_TOKEN
    return fetch(url, {
      method: 'POST',
      body: JSON.stringify({ values }),
    })
    .then((response) => response.json())
    .catch((error) => console.error(error))
}

export function createAddress(values) {
  const url = 'https://api.pinet-industrie.com/react/user/create_address?api_key=' + API_TOKEN
    return fetch(url, {
      method: 'POST',
      body: JSON.stringify({ values }),
    })
    .then((response) => response.json())
    .catch((error) => console.error(error))
}

export function getProducts(language, host) {
  const url = 'https://api.pinet-industrie.com/react/products?language=' + language + '&host=' + host + '&api_key=' + API_TOKEN
  return fetch(url)
    .then((response) => response.json())
    .catch((error) => console.error(error))
}

export function getProductDetail(matiere, epaisseur, largeur, percable, fraisurable, quantity, longueur, host) {
  const url = 'https://api.pinet-industrie.com/react/product_detail?host=' + host + '&api_key=' + API_TOKEN
  return fetch(url, {
      method: 'POST',
      body: JSON.stringify({ matiere: matiere, epaisseur: epaisseur, largeur: largeur, fraisurable: fraisurable, percable: percable, quantity: quantity, longueur: longueur }),
    })
    .then((response) => response.json())
    .catch((error) => console.error(error))
}

export function saveProductDetail(user, id, title, matiere, epaisseur, largeur, percable, fraisurable, quantity, longueur, host) {
  const url = 'https://api.pinet-industrie.com/react/product_detail_save?host=' + host + '&api_key=' + API_TOKEN
  return fetch(url, {
      method: 'POST',
      body: JSON.stringify({ user: user, id: id, title: title, matiere: matiere, epaisseur: epaisseur, largeur: largeur, fraisurable: fraisurable, percable: percable, quantity: quantity, longueur: longueur }),
    })
    .then((response) => response.json())
    .catch((error) => console.error(error))
}

export function getCart(id, email, host) {
  const url = 'https://api.pinet-industrie.com/react/user/check_cart?api_key=' + API_TOKEN + '&host=' + host
  return fetch(url, {
      method: 'POST',
      body: JSON.stringify({ id: id, email: email }),
    })
    .then((response) => response.json())
    .catch((error) => console.error(error))
}

export function getTotal(id, email, host) {
  const url = 'https://api.pinet-industrie.com/react/user/check_total?api_key=' + API_TOKEN + '&host=' + host
  return fetch(url, {
      method: 'POST',
      body: JSON.stringify({ id: id, email: email }),
    })
    .then((response) => response.json())
    .catch((error) => console.error(error))
}

export function getProjects(id, email, host) {
  const url = 'https://api.pinet-industrie.com/react/user/check_projects?api_key=' + API_TOKEN + '&host=' + host
  return fetch(url, {
      method: 'POST',
      body: JSON.stringify({ id: id, email: email }),
    })
    .then((response) => response.json())
    .catch((error) => console.error(error))
}

export function getProjectsSaved(id, user, email, host) {
  const url = 'https://api.pinet-industrie.com/react/user/check_project_saved?api_key=' + API_TOKEN + '&host=' + host
  return fetch(url, {
      method: 'POST',
      body: JSON.stringify({ id: id, email: email, user: user }),
    })
    .then((response) => response.json())
    .catch((error) => console.error(error))
}

export function deleteProductDetail(user, id, email, host) {
  const url = 'https://api.pinet-industrie.com/react/user/check_project_delete?api_key=' + API_TOKEN + '&host=' + host
  return fetch(url, {
      method: 'POST',
      body: JSON.stringify({ user: user, email: email, id: id }),
    })
    .then((response) => response.json())
    .catch((error) => console.error(error))
}

export function addToCart(user, email, quantity, product_id, percable, fraisurable, longueur, host) {
  const url = 'https://api.pinet-industrie.com/react/cart/add?api_key=' + API_TOKEN + '&host=' + host
  return fetch(url, {
      method: 'POST',
      body: JSON.stringify({ user: user, email: email, product_id: product_id, quantity: quantity, percable: percable, fraisurable: fraisurable, longueur: longueur }),
    })
    .then((response) => response.json())
    .catch((error) => console.error(error))
}

export function deleteCart(user, token, id, host) {
  const url = 'https://api.pinet-industrie.com/react/cart/delete_to_cart?api_key=' + API_TOKEN + '&host=' + host
  return fetch(url, {
      method: 'POST',
      body: JSON.stringify({ user: user, token: token, product_id: id }),
    })
    .then((response) => response.json())
    .catch((error) => console.error(error))
}







export function getImageFromApi (name) {
  return 'https://www.capsulecafe.com' + name
}

export function getImageFromUrl (name) {
  return name
}

export function getOrderView(email, token, idOrder) {
  const url = 'https://api.capsulecafe.fr/react/user/get_user_order_view?api_key=' + API_TOKEN
  return fetch(url, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email: email, token: token, orderid: idOrder }),
    })
    .then((response) => response.json())
    .catch((error) => console.error(error))
}
