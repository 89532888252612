import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import MetaTags from 'react-meta-tags';
import { Translation } from 'react-i18next';
import styles from '../styles/app.module.css';
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext } from 'pure-react-carousel';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import imageindex from '../Svg/index.svg';
import Stepper from '../Components/Stepper';
import HeaderCart from '../Components/HeaderCart';
import CartView from '../Components/CartView';
import TextField from '@material-ui/core/TextField';
import InputBase from '@material-ui/core/InputBase';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import { HeadIcon, ShoppingcartIcon, CircleIcon, ShipIcon, BookIcon } from '../Svg/Icons';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import { getOrderAdress, createAddress, getOrderChangeAdress } from '../Api/RestApi'
import { postcodeValidator, postcodeValidatorExistsForCountry } from 'postcode-validator';
import {
  checkVAT,
  andorra,
  austria,
  belgium,
  bulgaria,
  croatia,
  cyprus,
  czechRepublic,
  denmark,
  estonia,
  europe,
  finland,
  france,
  germany,
  greece,
  hungary,
  ireland,
  italy,
  latvia,
  lithuania,
  luxembourg,
  malta,
  netherlands,
  norway,
  poland,
  portugal,
  romania,
  russia,
  serbia,
  slovakiaRepublic,
  slovenia,
  spain,
  sweden,
  switzerland,
  unitedKingdom
} from 'jsvat';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
  useHistory,
  useLocation
} from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  account_content: {
    margin: theme.spacing(4),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(5),
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
    backgroundColor: "#fff",
    [theme.breakpoints.down('xs')]: {
       margin: theme.spacing(1),
       padding: theme.spacing(2),
    },
  },
  paper: {
    padding: theme.spacing(4),
    [theme.breakpoints.down('xs')]: {
       paddingLeft: theme.spacing(2),
       paddingRight: theme.spacing(2),
    },
  },
  paper2: {
    padding: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
       paddingLeft: theme.spacing(2),
       paddingRight: theme.spacing(2),
    },
    marginBottom: theme.spacing(4),
  },
  container: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-start',
    flexWrap: 'nowrap',
    height: '35px',
    marginBottom: theme.spacing(4),
  },
  container2: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-start',
    flexWrap: 'nowrap',
    height: '35px',
    marginTop: theme.spacing(4),
    marginBottom: 0,
    padding: theme.spacing(2),
  },
  header_class: {
    fontSize: '24px',
    margin: '0',
    padding: '0',
    lineHeight: '30px',
    marginBottom: '3px',
    fontWeight: theme.typography.fontWeightBold,
    letterSpacing: '2px',
    margin: theme.spacing(3),
    [theme.breakpoints.down('xs')]: {
       margin: theme.spacing(1),
       fontSize: '16px',
       lineHeight: '22px',
    },
  },
  header_content: {
    margin: theme.spacing(2),
    marginLeft: theme.spacing(4),
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-start',
    [theme.breakpoints.down('xs')]: {
       margin: theme.spacing(1),
    },
  },
  button: {
    fontSize: '15px',
    borderRadius: '30px',
    marginTop: theme.spacing(3),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    height: '60px',
    backgroundColor: theme.palette.success.main,
    color: theme.palette.success.contrastText,
    fontWeight: theme.typography.fontWeightBold,
    '&:hover': {
      backgroundColor: theme.palette.success.main,
      color: theme.palette.success.contrastText,
    },
  },
  buttonSvg: {
    fontSize: '20px',
    fill: theme.palette.primary.main,
    stroke: theme.palette.primary.main,
  },
  buttonSvg1: {
    fontSize: '35px',
    fill: theme.palette.primary.main,
    stroke: theme.palette.primary.main,
  },
  buttonSvg2: {
    fontSize: '30px',
    fill: 'none',
    stroke: theme.palette.secondary.main,
  },
  class: {
    fontSize: '13px',
    marginLeft: theme.spacing(2),
    lineHeight: '20px',
    marginBottom: '3px',
    fontWeight: theme.typography.fontWeightBold,
    textTransform: 'uppercase',
    letterSpacing: '2px',
    color: theme.palette.secondary.contrastText
  },
  legal: {
    fontSize: '13px',
    lineHeight: '20px',
    marginTop: theme.spacing(4),
    fontWeight: theme.typography.fontWeightLight,
    textTransform: 'uppercase',
    letterSpacing: '2px'
  },
  boxhead: {
    marginBottom: theme.spacing(4),
  },
  head: {
    fontSize: '13px',
    fontWeight: theme.typography.fontWeightBold,
    textTransform: 'uppercase',
    letterSpacing: '2px',
    marginBottom: theme.spacing(1),
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'space-between'
  },
  form: {
    borderRadius: '30px',
    marginTop: theme.spacing(3),
    backgroundColor: theme.palette.light.main,
    color: theme.palette.light.contrastText,
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    height: '60px',
  },
  circle: {
    width: '60px',
    height: '60px',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: 'auto',
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(3),
  },
  buttonLogin: {
    fontSize: '26px',
    fill: theme.palette.navtextcolor.main,
    stroke: theme.palette.navtextcolor.main,
  },
  buttonLogin2: {
    fontSize: '26px',
    fill: theme.palette.grey.A700,
    stroke: theme.palette.grey.A700,
  },
  error: {
    fontSize: '13px',
    fontWeight: theme.typography.fontWeightNormal,
    paddingLeft: theme.spacing(3),
    color: theme.palette.error.main,
  },
  switch: {
    fontSize: '13px',
    margin: theme.spacing(1),
    lineHeight: '20px',
    fontWeight: theme.typography.fontWeightRegular,
    textTransform: 'uppercase',
    letterSpacing: '2px'
  },
  switchlabel: {
    fontSize: '15px',
    margin: theme.spacing(1),
    lineHeight: '20px',
    fontWeight: theme.typography.fontWeightRegular,
    letterSpacing: '1px'
  },
  switchMargin: {
    height: '60px',
    marginTop: theme.spacing(3),
  },
  legal_container: {
    marginTop: theme.spacing(7),
  },
  success: {
    color: theme.palette.light.contrastText,
  },
  buttonValid: {
    fontSize: '15px',
    borderRadius: '30px',
    marginTop: theme.spacing(3),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    height: '60px',
    backgroundColor: theme.palette.success.main,
    color: theme.palette.success.contrastText,
    fontWeight: theme.typography.fontWeightBold,
    '&:hover': {
      backgroundColor: theme.palette.success.main,
      color: theme.palette.success.contrastText,
    },
  },
}));

function Shipping(props) {
  const classes = useStyles();
  const { language, translations, logged, details, shipping, countries } = props
  const [locale, setLocale] = React.useState(language);
  let history = useHistory();

  const handleShipping = () => {
    getOrderAdress(details.id, details.email, 1, window.location.hostname).then(data => {
      const action = { type: "TOGGLE_SHIPPING", value: data }
      props.dispatch(action)
      setValues({ ...values,
        addresses: data.addresses,
        addressid: data.id,
        company: data.company,
        firstname: data.firstname,
        validFirstname: true,
        firstnameError: false,
        lastname: data.lastname,
        validLastname: true,
        lastnameError: false,
        address1: data.address,
        validAddress1: true,
        address1Error: false,
        address2: data.subaddress,
        validAddress2: true,
        address2Error: false,
        postcode: data.postcode,
        validPostcode: true,
        postcodeError: false,
        city: data.city,
        country: data.country,
        isLoaded: true,
        isChanged: false
      });
    })
  }

  const handleChangeAddress = (prop) => (event) => {
    getOrderChangeAdress(details.id, details.email, 1, prop, window.location.hostname).then(data => {
      const action = { type: "TOGGLE_SHIPPING", value: data }
      props.dispatch(action)
      setValues({ ...values,
        addresses: data.addresses,
        addressid: data.id,
        company: data.company,
        firstname: data.firstname,
        validFirstname: true,
        firstnameError: false,
        lastname: data.lastname,
        validLastname: true,
        lastnameError: false,
        address1: data.address,
        validAddress1: true,
        address1Error: false,
        address2: data.subaddress,
        validAddress2: true,
        address2Error: false,
        postcode: data.postcode,
        validPostcode: true,
        postcodeError: false,
        city: data.city,
        country: data.country,
        isLoaded: true,
        isChanged: false
      });
    })
  }

  const handleChangeLegal = (country, isCompany) => {
    let postcode = values.postcode
    let validPostcode = values.validPostcode
    if (country != values.country) {
      postcode = ''
      validPostcode = false
    }
    setValues({ ...values, country: country, companyError: false, postcode: postcode, validPostcode: validPostcode, isChanged: true });
  };

  const countriesList: ReadonlyArray<Country> = [
    andorra,
    austria,
    belgium,
    bulgaria,
    croatia,
    cyprus,
    czechRepublic,
    denmark,
    estonia,
    europe,
    finland,
    france,
    germany,
    greece,
    hungary,
    ireland,
    italy,
    latvia,
    lithuania,
    luxembourg,
    malta,
    netherlands,
    norway,
    poland,
    portugal,
    romania,
    russia,
    serbia,
    slovakiaRepublic,
    slovenia,
    spain,
    sweden,
    switzerland,
    unitedKingdom
  ];

  const [values, setValues] = React.useState({
    company: '',
    validCompany: true,
    companyError: false,
    firstname: '',
    validFirstname: true,
    firstnameError: false,
    lastname: '',
    validLastname: true,
    lastnameError: false,
    address1: '',
    validAddress1: true,
    address1Error: false,
    address2: '',
    validAddress2: true,
    address2Error: false,
    postcode: '',
    validPostcode: true,
    postcodeError: false,
    city: '',
    validCity: true,
    cityError: false,
    country: language.toUpperCase(),
  });

  const handleChange = (prop, error, valid, min, max) => (event) => {
    var isValid = false;
    if ((event.target.value.length >= min) && (event.target.value.length <= max)) {
      isValid = true;
    }
    setValues({ ...values, [prop]: event.target.value, [valid]: isValid, [error]: false, isChanged: true });
  }

  const handleChangePostcode = (prop) => (event) => {
    let postcode = postcodeValidator(event.target.value, values.country)
    setValues({ ...values, [prop]: event.target.value, validPostcode: postcode, postcodeError: false, isChanged: true});
  }

  const handleChangeCountry = (prop) => (event) => {
    handleChangeLegal(event.target.value, values.isCompany)
  }

  const createAction = (event) => {
    let isValid = true;
    let companyError = false;
    let firstnameError = false;
    let lastnameError = false;
    let address1Error = false;
    let address2Error = false;
    let postcodeError = false;
    let cityError = false;

    if ((!values.validFirstname) ||
        (!values.validLastname) ||
        (!values.validAddress1) ||
        (!values.validPostcode) ||
        (!values.validCity))
    {
          isValid = false;
    }

    if (!isValid) {
      if (!values.validFirstname) {
        firstnameError = true
      }
      if (!values.validLastname) {
        lastnameError = true
      }
      if (!values.validAddress1) {
        address1Error = true
      }
      if (!values.validAddress2) {
        address2Error = true
      }
      if (!values.validPostcode) {
        postcodeError = true
      }
      if (!values.validCity) {
        cityError = true
      }
    }

    setValues({ ...values,
      companyError: companyError,
      firstnameError: firstnameError,
      lastnameError: lastnameError,
      address1Error: address1Error,
      address2Error: address2Error,
      postcodeError: postcodeError,
      cityError: cityError,
    });

    const valueCreate = {
      'domain': window.location.hostname,
      'address': values.addressid,
      'type': 1,
      'user': details.id,
      'company':values.company,
      'firstname':values.firstname,
      'lastname':values.lastname,
      'address1':values.address1,
      'address2':values.address2,
      'postcode':values.postcode,
      'city':values.city,
      'country':values.country,
    }

    if (isValid) {
      createAddress(
        valueCreate
      ).then(data => {
        if (data.success) {
          const action = { type: "TOGGLE_SHIPPING", value: data }
          props.dispatch(action)
          setValues({ ...values,
            addressid: data.id,
            firstname: data.firstname,
            validFirstname: true,
            firstnameError: false,
            lastname: data.lastname,
            validLastname: true,
            lastnameError: false,
            address1: data.address,
            validAddress1: true,
            address1Error: false,
            address2: data.subaddress,
            validAddress2: true,
            address2Error: false,
            postcode: data.postcode,
            validPostcode: true,
            postcodeError: false,
            city: data.city,
            isLoaded: true,
            isChanged: false
          });
        }
      })
    }
  }

  useEffect(()=>{
    handleShipping()
    window.scrollTo(0, 0);
  }, [])

  if (!logged) {
    return (
      <Redirect to={{ pathname: "/" + language  + "/" + translations["link_login_url"] }} />
    )
  } else {
    if (values.isLoaded) {
      return (
        <div>
          <MetaTags>
            <title>{translations["link_account"]}</title>
            <meta name="description" content="Some description." />
            <meta property="og:title" content={translations["link_shipping"]} />
            <meta property="og:image" content="path/to/image.jpg" />
          </MetaTags>
          <div>
            <Grid container spacing={0} justify="space-between" alignItems="center" alignContent="center">
              <Grid item xs={12} lg={5} className={classes.header_content}>
                <ShipIcon className={classes.buttonSvg1} />
                <h1 className={classes.header_class}>{translations["link_shipping"]}</h1>
              </Grid>
              <Grid item xs={12} lg={5}>
                <HeaderCart translations={translations} />
              </Grid>
            </Grid>
          </div>
          <div className={classes.account_content}>
            <Stepper translations={translations} step={1} />
            <div className={classes.root}>
              <Grid container spacing={5}>
                <Grid item xs={12} lg={7}>
                  <Box border={1} borderColor="grey.200" borderRadius={5} className={classes.paper}>
                    <div className={classes.container}>
                      <ShipIcon className={classes.buttonSvg1} />
                      <div className={classes.class}>{translations["link_shipping"]}</div>
                    </div>
                    <Grid container spacing={5}>
                      <Grid item xs={12}>
                      <InputBase
                        id="company"
                        variant="filled"
                        color="secondary"
                        fullWidth
                        required
                        value={values.company}
                        onChange={handleChange('company', 'companyError', 'validCompany', '2', '30')}
                        placeholder={translations["link_create_account_company"]}
                        className={classes.form}
                        inputProps={{
                          'aria-label': 'company',
                        }}
                        endAdornment={
                          <InputAdornment position="end">
                            {values.validCompany ? <CheckIcon className={classes.success} /> : ''}
                          </InputAdornment>
                        }
                      />
                      {values.companyError ? <FormHelperText className={classes.error}>{translations["link_account_create_length_error"]}</FormHelperText> : ''}
                      <InputBase
                          id="firstname"
                          variant="filled"
                          color="secondary"
                          fullWidth
                          required
                          value={values.firstname}
                          onChange={handleChange('firstname', 'firstnameError', 'validFirstname', '2', '30')}
                          placeholder={translations["link_create_account_firstname"]}
                          className={classes.form}
                          inputProps={{
                            'aria-label': 'emailLogin',
                          }}
                          endAdornment={
                            <InputAdornment position="end">
                              {values.validFirstname ? <CheckIcon className={classes.success} /> : ''}
                            </InputAdornment>
                          }
                        />
                        <InputBase
                          id="lastname"
                          variant="filled"
                          color="secondary"
                          fullWidth
                          required
                          value={values.lastname}
                          onChange={handleChange('lastname', 'lastnameError', 'validLastname', '2', '30')}
                          placeholder={translations["link_create_account_lastname"]}
                          className={classes.form}
                          inputProps={{
                            'aria-label': 'emailLogin',
                          }}
                          endAdornment={
                            <InputAdornment position="end">
                              {values.validFirstname ? <CheckIcon className={classes.success} /> : ''}
                            </InputAdornment>
                          }
                        />
                        <InputBase
                          id="address1"
                          variant="filled"
                          color="secondary"
                          fullWidth
                          required
                          value={values.address1}
                          onChange={handleChange('address1', 'address1Error', 'validAddress1', '2', '30')}
                          placeholder={translations["link_create_account_address1"]}
                          className={classes.form}
                          inputProps={{
                            'aria-label': 'emailLogin',
                          }}
                          endAdornment={
                            <InputAdornment position="end">
                              {values.validAddress1 ? <CheckIcon className={classes.success} /> : ''}
                            </InputAdornment>
                          }
                        />
                        {values.address1Error ? <FormHelperText className={classes.error}>{translations["link_account_create_length_error"]}</FormHelperText> : ''}
                        <InputBase
                          id="address2"
                          variant="filled"
                          color="secondary"
                          fullWidth
                          required
                          value={values.address2}
                          onChange={handleChange('address2', 'address2Error', 'validAddress2', '2', '30')}
                          placeholder={translations["link_create_account_address2"]}
                          className={classes.form}
                          inputProps={{
                            'aria-label': 'emailLogin',
                          }}
                          endAdornment={
                            <InputAdornment position="end">
                              {values.validAddress2 ? <CheckIcon className={classes.success} /> : ''}
                            </InputAdornment>
                          }
                        />
                        <InputBase
                          id="postcode"
                          variant="filled"
                          color="secondary"
                          fullWidth
                          required
                          value={values.postcode}
                          onChange={handleChangePostcode('postcode')}
                          placeholder={translations["link_create_account_postcode"]}
                          className={classes.form}
                          inputProps={{
                            'aria-label': 'emailLogin',
                          }}
                          endAdornment={
                            <InputAdornment position="end">
                              {values.validPostcode ? <CheckIcon className={classes.success} /> : ''}
                            </InputAdornment>
                          }
                        />
                        {values.postcodeError ? <FormHelperText className={classes.error}>{translations["link_account_create_postcode_error"]}</FormHelperText> : ''}
                        <InputBase
                          id="city"
                          variant="filled"
                          color="secondary"
                          fullWidth
                          required
                          value={values.city}
                          onChange={handleChange('city', 'cityError', 'validCity', '2', '30')}
                          placeholder={translations["link_create_account_city"]}
                          className={classes.form}
                          inputProps={{
                            'aria-label': 'emailLogin',
                          }}
                          endAdornment={
                            <InputAdornment position="end">
                              {values.validCity ? <CheckIcon className={classes.success} /> : ''}
                            </InputAdornment>
                          }
                        />
                        {values.cityError ? <FormHelperText className={classes.error}>{translations["link_account_create_length_error"]}</FormHelperText> : ''}
                        <Select
                          labelId="demo-simple-select-label"
                          variant="standard"
                          color="secondary"
                          fullWidth
                          id="demo-simple-select"
                          value={values.country}
                          onChange={handleChangeCountry('country')}
                          placeholder={translations["link_create_account_siret"]}
                          className={classes.form}
                        >
                        {countries.map(({ code, title }, index) => (
                          <MenuItem value={code}>{title}</MenuItem>
                        ))}
                        </Select>
                        {values.isChanged ?
                          <Button onClick={createAction} fullWidth variant="contained" color="inherit" className={classes.button}>{translations["address_to_save"]}</Button>
                          : ''
                        }

                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
                <Grid item xs={12} lg={5}>
                  <Box border={1} borderColor="grey.200" borderRadius={5} className={classes.paper2}>
                    <div className={classes.container2}>
                      <BookIcon className={classes.buttonSvg1} />
                      <div className={classes.class}>{translations["addresses_book"]}</div>
                    </div>
                    <div>
                      <List component="nav" aria-label="addresses">
                        {values.addresses.map((value, index) => (
                          <ListItem onClick={handleChangeAddress(value['id'])} button>
                            <ListItemText
                              primary={value['title_address']}
                              secondary={value['full_address']}
                            />
                            <ListItemSecondaryAction>
                              <IconButton onClick={handleChangeAddress(value['id'])} edge="end" aria-label="comments">
                                <CircleIcon className={classes.buttonSvg} />
                              </IconButton>
                            </ListItemSecondaryAction>
                          </ListItem>
                        ))}
                      </List>
                    </div>
                  </Box>
                  <Link className={classes.link} to={'/' + locale + '/' + translations["link_payment_url"]}>
                    <Button fullWidth variant="contained" color="inherit" className={classes.buttonValid}>{translations["order_next"]}</Button>
                  </Link>
                </Grid>
              </Grid>
            </div>
            <div className={classes.legal}>{translations["link_login_legal"]}</div>
          </div>
        </div>
      )
    } else {
      return null;
    }
  }
}

const mapStateToProps = function(state) {
  return {
    language: state.paramsReducer.language,
    content: state.paramsReducer.content,
    logged: state.accountReducer.logged,
    details: state.accountReducer.details,
    shipping: state.accountReducer.shipping,
    base: state.paramsReducer.base,
  }
}

export default connect(mapStateToProps)(Shipping);
