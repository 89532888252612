import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import MetaTags from 'react-meta-tags';
import { Translation } from 'react-i18next';
import styles from '../styles/app.module.css';
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext } from 'pure-react-carousel';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import imageindex from '../Svg/index.svg';
import Stepper from '../Components/Stepper';
import HeaderCart from '../Components/HeaderCart';
import Cart from '../Components/Cart';
import TextField from '@material-ui/core/TextField';
import InputBase from '@material-ui/core/InputBase';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import { HeadIcon, ShoppingcartIcon, CircleIcon, TrashIcon, CrayonIcon } from '../Svg/Icons';
import NumberFormat from 'react-number-format';
import { checkEmailApi, getLoginDetail, getCart, deleteCart, getTotal } from '../Api/RestApi'
import charnierepercee from '../Svg/charnierepercee.svg';
import charniere from '../Svg/charniere.svg';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
  useHistory,
  useLocation
} from "react-router-dom";

const drawerWidth = 340;

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    maxWidth: '100vw'
  },
  account_content: {
    margin: theme.spacing(4),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(5),
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
    backgroundColor: "#fff",
    [theme.breakpoints.down('xs')]: {
       margin: theme.spacing(1),
       padding: theme.spacing(2),
    },
  },
  paper: {
    padding: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
       paddingLeft: theme.spacing(2),
       paddingRight: theme.spacing(2),
    },
  },
  container: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-start',
    flexWrap: 'nowrap',
    height: '35px',
    marginTop: theme.spacing(4),
    marginBottom: 0,
    padding: theme.spacing(2),
  },
  header_class: {
    fontSize: '24px',
    margin: '0',
    padding: '0',
    lineHeight: '30px',
    marginBottom: '3px',
    fontWeight: theme.typography.fontWeightBold,
    letterSpacing: '2px',
    margin: theme.spacing(3),
    [theme.breakpoints.down('xs')]: {
       margin: theme.spacing(1),
       fontSize: '16px',
       lineHeight: '22px',
    },
  },
  header_content: {
    margin: theme.spacing(2),
    marginLeft: theme.spacing(4),
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-start',
    [theme.breakpoints.down('xs')]: {
       margin: theme.spacing(1),
    },
  },
  button: {
    fontSize: '15px',
    borderRadius: '30px',
    marginTop: theme.spacing(3),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    height: '60px',
    backgroundColor: theme.palette.error.main,
    color: theme.palette.secondary.main,
    fontWeight: theme.typography.fontWeightBold,
    '&:hover': {
      backgroundColor: theme.palette.error.main,
      color: theme.palette.secondary.main,
    },
  },
  button1: {
    fontSize: '15px',
    borderRadius: '30px',
    marginTop: theme.spacing(3),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    height: '60px',
    backgroundColor: theme.palette.success.main,
    color: theme.palette.secondary.main,
    fontWeight: theme.typography.fontWeightBold,
    '&:hover': {
      backgroundColor: theme.palette.success.main,
      color: theme.palette.secondary.main,
    },
  },
  buttonValid: {
    fontSize: '15px',
    borderRadius: '30px',
    marginTop: theme.spacing(3),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    height: '60px',
    backgroundColor: theme.palette.success.main,
    color: theme.palette.success.contrastText,
    fontWeight: theme.typography.fontWeightBold,
    '&:hover': {
      backgroundColor: theme.palette.success.main,
      color: theme.palette.success.contrastText,
    },
  },
  buttonSvg: {
    fontSize: '20px',
    fill: theme.palette.primary.main,
    stroke: theme.palette.primary.main,
  },
  buttonSvg1: {
    fontSize: '35px',
    fill: theme.palette.primary.main,
    stroke: theme.palette.primary.main,
  },
  buttonSvg2: {
    fontSize: '30px',
    fill: 'none',
    stroke: theme.palette.primary.main,
  },
  class: {
    fontSize: '13px',
    marginLeft: theme.spacing(2),
    lineHeight: '20px',
    marginBottom: '3px',
    fontWeight: theme.typography.fontWeightBold,
    textTransform: 'uppercase',
    letterSpacing: '2px',
    color: theme.palette.secondary.contrastText
  },
  legal: {
    fontSize: '13px',
    lineHeight: '20px',
    marginTop: theme.spacing(4),
    fontWeight: theme.typography.fontWeightLight,
    textTransform: 'uppercase',
    letterSpacing: '2px'
  },
  boxhead: {
    marginBottom: theme.spacing(4),
  },
  head: {
    fontSize: '13px',
    fontWeight: theme.typography.fontWeightBold,
    textTransform: 'uppercase',
    letterSpacing: '2px',
    marginBottom: theme.spacing(1),
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'space-between'
  },
  form: {
    borderRadius: '30px',
    marginTop: theme.spacing(3),
    backgroundColor: theme.palette.info.main,
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    height: '60px',
  },
  circle: {
    width: '60px',
    height: '60px',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: 'auto',
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(3),
  },
  buttonLogin: {
    fontSize: '26px',
    fill: theme.palette.navtextcolor.main,
    stroke: theme.palette.navtextcolor.main,
  },
  buttonLogin2: {
    fontSize: '26px',
    fill: theme.palette.grey.A700,
    stroke: theme.palette.grey.A700,
  },
  error: {
    fontSize: '13px',
    fontWeight: theme.typography.fontWeightNormal,
    paddingLeft: theme.spacing(3),
    color: theme.palette.error.main,
  },
  classTotal: {
    textAlign: 'right',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(4),
  },
}));

function AccountHome(props) {
  const classes = useStyles();
  const { language, translations, logged, details } = props
  const [locale, setLocale] = React.useState(language);
  let history = useHistory();

  const [values, setValues] = React.useState({
    products: [],
    isLoaded: false
  });


  const handleCart = () => {
    getCart(details.id, details.email, window.location.hostname).then(data => {
      const action = { type: "TOGGLE_CART_DETAIL", value: data }
      props.dispatch(action)
      getTotal(details.id, details.email, window.location.hostname).then(data1 => {
        const action1 = { type: "TOGGLE_CART", value: data1 }
        props.dispatch(action1)
        console.log(data.products)

        setValues({
          ...values,
          products: data.products,
          isLoaded: true
        });
      });
    })
  }

  const handleDelete = (prop) => (event) => {
    deleteCart(details.id, details.email, prop, window.location.hostname).then(data => {
      handleCart()
    })
  };

  useEffect(()=>{
    window.scrollTo(0, 0);
    handleCart()
  }, [])

  if (!logged) {
    return (
      <Redirect to={{ pathname: "/" + language  + "/" + translations["link_login_url"] }} />
    )
  } else {
    return (
      <div>
        <MetaTags>
          <title>{translations["link_shoppingcart"]}</title>
          <meta name="description" content="Some description." />
          <meta property="og:title" content={translations["link_shoppingcart"]} />
          <meta property="og:image" content="path/to/image.jpg" />
        </MetaTags>
        <div>
          <Grid container spacing={0} justify="space-between" alignItems="center" alignContent="center">
            <Grid item xs={12} lg={5} className={classes.header_content}>
              <ShoppingcartIcon className={classes.buttonSvg1} />
              <h1 className={classes.header_class}>{translations["link_shoppingcart"]}</h1>
            </Grid>
            <Grid item xs={12} lg={5}>
              <HeaderCart translations={translations} />
            </Grid>
          </Grid>
        </div>
        <div className={classes.account_content}>
          <Stepper translations={translations} step={0} />
          <div className={classes.root}>
            <Grid container spacing={5}>
              <Grid item xs={12} lg={7}>
                <Box border={1} borderColor="grey.200" borderRadius={5} className={classes.paper}>
                  <div className={classes.container}>
                    <CrayonIcon className={classes.buttonSvg2} />
                    <div className={classes.class}>{translations["link_projects_new_title"]}</div>
                  </div>
                  <div>
                    <List component="nav" aria-label="mailbox folders">
                    {values.products.map((value, index) => (
                      <ListItem>
                        <ListItem>
                        {!value['percage'] ?
                        <img width="200" height="139" className={styles.imgfluid} src={charniere} />
                        :
                        <img width="200" height="139" className={styles.imgfluid} src={charnierepercee} />
                        }
                        </ListItem>
                        <ListItemText
                          primary={translations["charniere"]}
                          secondary={value['attributes']['att5'] + ' - ' + translations["link_projects_longueur"] + ' ' + value['longueur'] + 'mm'}
                        />
                      </ListItem>
                    ))}
                    </List>
                  </div>
                </Box>
              </Grid>
              <Grid item xs={12} lg={5}>
                <Box border={1} borderColor="grey.200" borderRadius={5} className={classes.paper}>
                  <div className={classes.container}>
                    <ShoppingcartIcon className={classes.buttonSvg2} />
                    <div className={classes.class}>{translations["link_shoppingcart"]}</div>
                  </div>
                  <div>
                    <List component="nav" aria-label="mailbox folders">
                    {values.products.map((value, index) => (
                      <ListItem>
                        <ListItemText
                          primary={translations["charniere"]}
                          secondary={value['attributes']['att5'] + ' - ' + translations["link_projects_longueur"] + ' ' + value['longueur'] + 'mm'}
                        />
                        <ListItemText
                          className={classes.classTotal}
                          primary={value['quantity']}
                          secondary=<NumberFormat value={value['price']} displayType={'text'} thousandSeparator={false} decimalScale={2} suffix={'€'} />
                        />
                        <ListItemSecondaryAction>
                          <IconButton onClick={handleDelete(value['product_id'])} edge="end" aria-label="comments">
                            <TrashIcon className={classes.buttonSvg2} />
                          </IconButton>
                        </ListItemSecondaryAction>
                      </ListItem>
                    ))}
                    </List>
                  </div>
                </Box>
                <Link className={classes.link} to={'/' + locale + '/' + translations["link_shipping_url"]}>
                  <Button fullWidth variant="contained" color="inherit" className={classes.buttonValid}>{translations["order_next"]}</Button>
                </Link>
              </Grid>
            </Grid>
          </div>
          <div className={classes.legal}>{translations["link_login_legal"]}</div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = function(state) {
  return {
    language: state.paramsReducer.language,
    content: state.paramsReducer.content,
    logged: state.accountReducer.logged,
    details: state.accountReducer.details,
    base: state.paramsReducer.base,
  }
}

export default connect(mapStateToProps)(AccountHome);
