import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { HeadIcon, CircleIcon } from '../Svg/Icons';

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(2),
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(4),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    borderRadius: '18px',
    minWidth: '300px',
    height: '60px',
    background: theme.palette.buttonindex.main,
    color: theme.palette.buttonindex.contrastText,
    '&:hover': {
       background: theme.palette.buttonindex.main,
    },
    justifyContent: 'space-between',
    [theme.breakpoints.down('xs')]: {
      minWidth: '280px',
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
  },
  buttonSvg: {
    fontSize: '20px',
    fill: theme.palette.buttonindex.main,
    stroke: theme.palette.buttonindex.main,
    marginTop: '7px'
  },
  buttonSvgInverted: {
    fontSize: '38px',
    fill: theme.palette.buttonindex.dark,
    stroke: theme.palette.buttonindex.dark,
    marginLeft: '8px'
  },
  circle: {
    width: '34px',
    height: '34px',
    backgroundColor: theme.palette.buttonindex.contrastText,
    borderRadius: '50%',
  },
  circleInverted: {
    width: '34px',
    height: '34px',
    backgroundColor: '#f6f6f6',
    borderRadius: '50%',
    position: 'relative'
  },
  badgeSuccess: {
    width: '14px',
    height: '14px',
    backgroundColor: theme.palette.success.main,
    borderRadius: '50%',
    position: 'absolute',
    bottom: '0',
    right: '-14px'
  },
  badgeDanger: {
    width: '14px',
    height: '14px',
    backgroundColor: theme.palette.error.main,
    borderRadius: '50%',
    position: 'absolute',
    bottom: '0',
    right: '-14px'
  },
  title: {
    textTransform: 'uppercase',
    fontSize: '14px',
    color: theme.palette.buttonindex.contrastText,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
    textAlign: 'center'
  }
}));

export function OldAccount(props) {
  const classes = useStyles(props);
  const { title } = props;
  return (
    <Button variant="contained" className={classes.button}>
      <div className={classes.circleInverted}>
        <HeadIcon className={classes.buttonSvgInverted} />
        <div className={classes.badgeSuccess}></div>
      </div>
      <span className={classes.title}>{title}</span>
      <div className={classes.circle}>
        <CircleIcon className={classes.buttonSvg} />
      </div>
    </Button>
  );
}

export function NewAccount(props) {
  const classes = useStyles(props);
  const { title } = props;
  return (
    <Button variant="contained" className={classes.button}>
      <div className={classes.circleInverted}>
        <HeadIcon className={classes.buttonSvgInverted} />
        <div className={classes.badgeDanger}></div>
      </div>
      <span className={classes.title}>{title}</span>
      <div className={classes.circle}>
        <CircleIcon className={classes.buttonSvg} />
      </div>
    </Button>
  );
}

export function MyAccount(props) {
  const classes = useStyles(props);
  const { title } = props;
  return (
    <Button variant="contained" className={classes.button}>
      <div className={classes.circleInverted}>
        <HeadIcon className={classes.buttonSvgInverted} />
        <div className={classes.badgeSuccess}></div>
      </div>
      <span className={classes.title}>{title}</span>
      <div className={classes.circle}>
        <CircleIcon className={classes.buttonSvg} />
      </div>
    </Button>
  );
}
