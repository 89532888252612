const initialState = { language: 'en', base: [], content: [] }

function params(state = initialState, action) {
  switch (action.type) {
    case 'TOGGLE_BASE':
      state.base = action.value
      return state
    case 'TOGGLE_LANGUAGE':
      state.language = action.value
      return state
    case 'TOGGLE_CONTENT':
      state.content = action.value
      return state
    default:
      return state
  }
}

export default params
