import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { MadeIcon, ShipIcon, PaymentIcon, AssitanceIcon } from '../Svg/Icons';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap',
    color: theme.palette.footertextcolor.main,
    backgroundColor: theme.palette.footercolor.main,
  },
  text: {
    fontSize: '14px',
  },
}));

export function Footer(props) {
  const classes = useStyles(props);
  const { blocks } = props;
  return (
  <div className={classes.root}>
    <p className={classes.text} dangerouslySetInnerHTML={{__html: blocks['footer_copyright'].title}}/><p className={classes.text}>{(new Date().getFullYear())}</p>
  </div>
  );
}
