import React from 'react';
import { createMuiTheme } from '@material-ui/core/styles'

export default function Theme(props) {
  console.log(props)
  return (
    createMuiTheme({
      "overrides": {
        "MuiCssBaseline": {
          "@global": {
            "html": {
              "WebkitFontSmoothing": 'auto',
            },
            "body": {
              "fontFamily": 'Montserrat',
              "backgroundColor": props.darkcolor,
            },
            "p":{
              "fontSize": "20px",
            },
            "a:-webkit-any-link":{
              "textDecoration": "none",
            },
            "input:-webkit-autofill": {
              "--box-shadow-color": props.darkcolor,
              "-webkit-box-shadow": "0 0 0 30px var(--box-shadow-color) inset !important",
            },
            "input:-webkit-autofill:hover": {
              "--box-shadow-color": props.darkcolor,
              "-webkit-box-shadow": "0 0 0 30px var(--box-shadow-color) inset !important",
            },
            "input:-webkit-autofill:focus": {
              "--box-shadow-color": props.darkcolor,
              "-webkit-box-shadow": "0 0 0 30px var(--box-shadow-color) inset !important",
            },
            "input:-webkit-autofill:active": {
              "--box-shadow-color": props.darkcolor,
              "-webkit-box-shadow": "0 0 0 30px var(--box-shadow-color) inset !important",
            },
            ".MuiInput-underline:before": {
              "borderBottom": "0"
            },
            ".MuiInput-underline:hover:not(.Mui-disabled):before": {
              "borderBottom": "0"
            },
            ".MuiSelect-select:focus": {
              "backgroundColor": "rgba(0, 0, 0, 0)"
            },
            ".MuiSelect-icon": {
              "right": "20px"
            },
            ".MuiSlider-markLabel": {
              "display": "none"
            },
            ".MuiSlider-marked": {
              "marginBottom": "0"
            },
            ".MuiSlider-valueLabel": {
              "color": '#fff',
            },
            ".MuiSwitch-root": {
              "width": "50px",
              "height": "30px",
              "padding": "0px"
            },
            ".MuiSwitch-switchBase": {
              "color": props.warningcolor,
              "opacity": ".9",
              "borderRadius": "15px",
              "padding": "2px"
            },
            ".MuiSwitch-thumb": {
              "width": "26px",
              "height": "26px",
            },
            ".MuiSwitch-track": {
              "backgroundColor": props.warningcolor,
              "opacity": ".5",
              "borderRadius": "15px",
            },
            ".MuiSwitch-colorSecondary.Mui-checked": {
              "backgroundColor": props.successcolor,
              "opacity": "1",
              "borderRadius": "15px",
            },
            ".MuiSwitch-colorSecondary.Mui-checked:hover": {
              "backgroundColor": props.successcolor,
              "opacity": "1",
              "borderRadius": "15px",
            },
            ".Mui-disabled": {
              "textAlign": "center"
            },
            "li":{
              "fontSize": "20px",
            },
            "h1":{
              "fontWeight": props.fonttitleweight,
              "fontSize": "50px",
              "textTransform": props.fonttitletransform,
              "textAlign": "center",
              "color": props.primarytextcolor,
              "& span": {
                "fontWeight": props.fonttitleweightbold,
                "fontSize": "50px",
                "display": "block"
              },
              '@media (max-width: 600px)': {
                 "fontSize": "20px",
                 "& span": {
                   "fontSize": "20px",
                 },
              },
            },
            "button": {
              "borderRadius": '15px',
            },
            ".MuiStepIcon-root":{
              "color": props.primarycolor,
            },
            ".MuiStepIcon-root.MuiStepIcon-active": {
              "color": props.successcolor,
            },
            ".MuiSvgIcon-root.MuiStepIcon-root.MuiStepIcon-completed": {
              "color": props.successcolor,
            },
            ".MuiStepIcon-text":{
              "color": props.successtextcolor,
              "fill": props.successtextcolor,
            },
          },
        },
      },
      "palette": {
        "common": {
          "black": "#000",
          "white": "#fff"
        },
        "navbgcolor": {
          "main": props.navbgcolor,
          "contrastText": props.navtextcolor,
        },
        "navtextcolor": {
          "main": props.navtextcolor,
        },
        "navbuttoncolor": {
          "main": props.navbuttoncolor,
          "contrastText": props.navbuttontextcolor,
        },
        "footercolor": {
          "main": props.footercolor,
        },
        "footertextcolor": {
          "main": props.footertextcolor,
        },
        "nav": {
          "main": props.navbuttontextcolor,
        },
        "buttonbgcolor": {
          "main": props.buttonbgcolor,
          "contrastText": props.buttontextcolor,
        },
        "buttonindex": {
          "main": props.buttonindexbg,
          "dark": props.buttonindexsvg,
          "contrastText": props.buttonindextext,
        },
        "primary": {
          "light": props.primarycolor,
          "main": props.primarycolor,
          "dark": props.primarycolor,
          "contrastText": props.primarytextcolor,
        },
        "secondary": {
          "light": props.secondarycolor,
          "main": props.secondarycolor,
          "dark": props.secondarycolor,
          "contrastText": props.secondarytextcolor,
        },
        "error": {
          "light": props.dangercolor,
          "main": props.dangercolor,
          "dark": props.dangercolor,
          "contrastText": props.dangertextcolor,
        },
        "warning": {
          "main": props.warningcolor,
          "main": props.warningcolor,
          "dark": props.warningcolor,
          "contrastText": props.warningtextcolor,
        },
        "info": {
          "light": props.infocolor,
          "main": props.infocolor,
          "dark": props.infocolor,
          "contrastText": props.infotextcolor,
        },
        "light": {
          "light": props.lightcolor,
          "main": props.lightcolor,
          "dark": props.lightcolor,
          "contrastText": props.lighttextcolor,
        },
        "success": {
          "light": props.successcolor,
          "main": props.successcolor,
          "dark": props.successcolor,
          "contrastText": props.successtextcolor,
        },
      },
      "typography": {
        "fontFamily": props.fonttext,
        "button": {
          "textTransform": 'none',
        }
      }
    })
  )
}
