import React from 'react';
import { connect } from 'react-redux';
import Drawer from './Structure/Drawer';
import { getParams } from './Api/RestApi'
import Home from './Pages/Home';
import AccountHome from './Pages/AccountHome';
import Login from './Pages/Login';
import CreateAccount from './Pages/CreateAccount';
import Shipping from './Pages/Shipping';
import Payment from './Pages/Payment';
import Validation from './Pages/Validation';
import ShoppingCart from './Pages/ShoppingCart';
import Order from './Pages/Order';
import Orders from './Pages/Orders';
import Project from './Pages/Project';
import AddressEdit from './Pages/AddressEdit';
import Password from './Pages/Password';
import ProjectEdit from './Pages/ProjectEdit';
import ProjectView from './Pages/ProjectView';
import Divider from '@material-ui/core/Divider';
import styles from './styles/app.module.css'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import i18n from "i18next";
import {initReactI18next, useTranslation} from 'react-i18next';
import { ReAssurance } from './Components/ReAssurance';
import { Footer } from './Components/Footer';

class Language extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      language: this.props.language,
      base: this.props.base,
      logged: this.props.logged,
    };
    this.switchLang = this.switchLang.bind(this)
  }

  loadParams(language) {
    getParams(language, window.location.hostname).then(data => {
      const action = { type: "TOGGLE_CONTENT", value: data }
      this.props.dispatch(action)
      this.setState({
        data: data,
        isLoading: false,
      })
    })
  }

  switchLang(lang) {
    const action = { type: "TOGGLE_LANGUAGE", value: lang }
    this.props.dispatch(action)
    i18n.use(initReactI18next).init({lng: lang});
    this.loadParams(lang);
    this.setState({
      language: lang,
    })
  }

  componentDidMount(){
    this.loadParams(i18n.language);
  }

  render() {
    if (this.state.isLoading) {
      return null
    } else {
      return (
        <Router>
          <div className={styles.grid}>
            <Drawer switchLang={this.switchLang} language={this.state.language} categories={this.state.data} translations={this.state.data.translations} {...this.props}/>
            <div className={styles.content}>
              <Switch>

                <Route path='/back-to-site' component={() => {
                     window.location.href = this.state.data.blocks['back_to_website'].url;
                     return null;
                }}/>
                <Route exact path="/:lang/">
                  <Home translations={this.state.data.translations} data={this.state.data} language={this.state.language} {...this.props} />
                </Route>
                <Route exact path={'/' + this.state.language + '/' + this.state.data.translations["link_account_url"]}>
                  <AccountHome translations={this.state.data.translations} {...this.props} />
                </Route>
                <Route exact path={'/' + this.state.language + '/' + this.state.data.translations["link_address_url"]}>
                  <AddressEdit translations={this.state.data.translations} countries={this.state.data.countries} {...this.props} />
                </Route>
                <Route exact path={'/' + this.state.language + '/' + this.state.data.translations["link_shipping_url"]}>
                  <Shipping translations={this.state.data.translations} countries={this.state.data.countries} {...this.props} />
                </Route>
                <Route exact path={'/' + this.state.language + '/' + this.state.data.translations["link_payment_url"]}>
                  <Payment translations={this.state.data.translations} countries={this.state.data.countries} {...this.props} />
                </Route>
                <Route exact path={'/' + this.state.language + '/' + this.state.data.translations["link_validation_url"]}>
                  <Validation translations={this.state.data.translations} countries={this.state.data.countries} {...this.props} />
                </Route>
                <Route exact path={'/' + this.state.language + '/' + this.state.data.translations["link_order_url"] + '/:id'}>
                  <Order translations={this.state.data.translations} countries={this.state.data.countries} {...this.props} />
                </Route>
                <Route exact path={'/' + this.state.language + '/' + this.state.data.translations["link_orders_url"]}>
                  <Orders translations={this.state.data.translations} countries={this.state.data.countries} {...this.props} />
                </Route>
                <Route exact path={'/' + this.state.language + '/' + this.state.data.translations["link_login_url"]}>
                  <Login translations={this.state.data.translations} data={this.state.data} {...this.props} />
                </Route>
                <Route exact path={'/' + this.state.language + '/' + this.state.data.translations["link_password_url"]}>
                  <Password translations={this.state.data.translations} data={this.state.data} {...this.props} />
                </Route>
                <Route exact path={'/' + this.state.language + '/' + this.state.data.translations["link_create_account_url"]}>
                  <CreateAccount translations={this.state.data.translations} data={this.state.data} countries={this.state.data.countries} {...this.props} />
                </Route>
                <Route exact path={'/' + this.state.language + '/' + this.state.data.translations["link_shoppingcart_url"]}>
                  <ShoppingCart translations={this.state.data.translations} countries={this.state.data.countries} {...this.props} />
                </Route>
                <Route path={'/' + this.state.language + '/' + this.state.data.translations["link_projects_url"]}>
                  <Route exact path={'/' + this.state.language + '/' + this.state.data.translations["link_projects_url"]}>
                    <Project translations={this.state.data.translations} data={this.state.data} {...this.props} />
                  </Route>
                  <Route path={'/' + this.state.language + '/' + this.state.data.translations["link_projects_url"] + '/' + this.state.data.translations["link_projects_edit_url"] + '/:id' }>
                    <ProjectView translations={this.state.data.translations} data={this.state.data} {...this.props} />
                  </Route>
                  <Route path={'/' + this.state.language + '/' + this.state.data.translations["link_projects_url"] + '/' + this.state.data.translations["link_projects_new_url"]}>
                    <ProjectView translations={this.state.data.translations} data={this.state.data} {...this.props} />
                  </Route>
                </Route>
              </Switch>
              <ReAssurance blocks={this.state.data.blocks} />
              <Footer blocks={this.state.data.blocks} />
            </div>
          </div>
        </Router>
      )
    }
  }
}

const mapStateToProps = function(state) {
  return {
    language: state.paramsReducer.language,
    content: state.paramsReducer.content,
    base: state.paramsReducer.base,
    logged: state.accountReducer.logged,
    details: state.accountReducer.details,
    cart: state.accountReducer.cart,
    cartdetail: state.accountReducer.cartdetail,
  }
}

export default connect(mapStateToProps)(Language);
