import React from 'react';
import { connect } from 'react-redux';
import MetaTags from 'react-meta-tags';
import { Translation } from 'react-i18next';
import styles from '../styles/app.module.css';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext } from 'pure-react-carousel';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import imageindex from '../Svg/index.svg';
import 'pure-react-carousel/dist/react-carousel.es.css';
import '@brainhubeu/react-carousel/lib/style.css';
import { OldAccount, NewAccount, MyAccount } from '../Components/ButtonAccount';
import {
  Link
} from "react-router-dom";

import TagManager from 'react-gtm-module';

const tagManagerArgs = {
    gtmId: 'GTM-PXGTP63'
};

TagManager.initialize(tagManagerArgs)

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    maxWidth: '100vw'
  },
}));


function Home(props) {

  const classes = useStyles();
  const { language, translations, data, logged, details } = props

    return (
      <div>
        <MetaTags>
          <title>{data.blocks['seo_home'].title }</title>
          <meta name="description" content={data.blocks['seo_home'].text } />
          <meta property="og:title" content={data.blocks['seo_home'].title } />
        </MetaTags>
        <div className={styles.content_wrapper}>
          <img width="300" height="139" className={styles.imgfluid} src={imageindex} alt={data.blocks['index_title'].title} />
          <h1 dangerouslySetInnerHTML={{__html: data.blocks['index_title'].title}}></h1>
          {!logged ?
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
            >
            <Link to={'/' + language + '/' + translations["link_account_url"]}>
              <OldAccount title={ translations['link_account_old'] } />
            </Link>
            <Link to={'/' + language + '/' + translations["link_create_account_url"]}>
              <NewAccount title={ translations['link_account_new'] } />
            </Link>
            </Grid>
          : <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
            >
              <Link to={'/' + language + '/' + translations["link_account_url"]}>
                <MyAccount title={ translations['link_account'] } />
              </Link>
            </Grid>

        }
        </div>
      </div>
    )
}



const mapStateToProps = function(state) {
  return {
    language: state.paramsReducer.language,
    content: state.paramsReducer.content,
    logged: state.accountReducer.logged,
    details: state.accountReducer.details,
    base: state.paramsReducer.base,
  }
}

export default connect(mapStateToProps)(Home);
