import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { HeadIcon, ShoppingcartIcon, TrashIcon } from '../Svg/Icons';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import NumberFormat from 'react-number-format';
import { checkEmailApi, getLoginDetail, getCart, deleteCart, getTotal } from '../Api/RestApi'

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: '100vw',
    [theme.breakpoints.down('sm')]: {
       overflowX: 'scroll',
       paddingLeft: '0',
       paddingRight: '0'
    },
  },
  account_content: {
    margin: theme.spacing(4),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(5),
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
    backgroundColor: "#fff",
    [theme.breakpoints.down('xs')]: {
       margin: theme.spacing(1),
       padding: theme.spacing(2),
    },
  },
  paper: {
    padding: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
       paddingLeft: theme.spacing(2),
       paddingRight: theme.spacing(2),
    },
  },
  container: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-start',
    flexWrap: 'nowrap',
    height: '35px',
    marginTop: theme.spacing(4),
    marginBottom: 0,
    padding: theme.spacing(2),
  },
  button: {
    fontSize: '15px',
    borderRadius: '30px',
    marginTop: theme.spacing(3),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    height: '60px',
    backgroundColor: theme.palette.error.main,
    color: theme.palette.secondary.main,
    fontWeight: theme.typography.fontWeightBold,
    '&:hover': {
      backgroundColor: theme.palette.error.main,
      color: theme.palette.secondary.main,
    },
  },
  buttonSvg: {
    fontSize: '20px',
    fill: theme.palette.primary.main,
    stroke: theme.palette.primary.main,
  },
  buttonSvg1: {
    fontSize: '35px',
    fill: theme.palette.primary.main,
    stroke: theme.palette.primary.main,
  },
  buttonSvg2: {
    fontSize: '30px',
    fill: 'none',
    stroke: theme.palette.primary.main,
    marginBottom: '5px',
  },
  class: {
    fontSize: '13px',
    marginLeft: theme.spacing(2),
    lineHeight: '20px',
    marginBottom: '3px',
    fontWeight: theme.typography.fontWeightBold,
    textTransform: 'uppercase',
    letterSpacing: '2px'
  },
  legal: {
    fontSize: '13px',
    lineHeight: '20px',
    marginTop: theme.spacing(4),
    fontWeight: theme.typography.fontWeightLight,
    textTransform: 'uppercase',
    letterSpacing: '2px'
  },
  boxhead: {
    marginBottom: theme.spacing(4),
  },
  head: {
    fontSize: '13px',
    fontWeight: theme.typography.fontWeightBold,
    textTransform: 'uppercase',
    letterSpacing: '2px',
    marginBottom: theme.spacing(1),
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'space-between'
  },
  form: {
    borderRadius: '30px',
    marginTop: theme.spacing(3),
    backgroundColor: theme.palette.info.main,
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    height: '60px',
  },
  circle: {
    width: '60px',
    height: '60px',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: 'auto',
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(3),
  },
  buttonLogin: {
    fontSize: '26px',
    fill: theme.palette.navtextcolor.main,
    stroke: theme.palette.navtextcolor.main,
  },
  buttonLogin2: {
    fontSize: '26px',
    fill: theme.palette.grey.A700,
    stroke: theme.palette.grey.A700,
  },
  error: {
    fontSize: '13px',
    fontWeight: theme.typography.fontWeightNormal,
    paddingLeft: theme.spacing(3),
    color: theme.palette.error.main,
  },
  classTotal: {
    textAlign: 'right',
    paddingLeft: theme.spacing(2),
  },
}));

function getSteps() {
  return ['1', '2', '3', '4'];
}

function Cart(props) {
  const { language, translations, logged, details, cart, cartdetail } = props
  const classes = useStyles();

  const [values, setValues] = React.useState({
    products: 0,
    isLoaded: false
  });

  const handleCart = () => {
    getCart(details.id, details.email, window.location.hostname).then(data => {
      const action = { type: "TOGGLE_CART_DETAIL", value: data }
      props.dispatch(action)
      getTotal(details.id, details.email, window.location.hostname).then(data1 => {
        const action1 = { type: "TOGGLE_CART", value: data1 }
        props.dispatch(action1)
        setValues({
          ...values,
          products: data.products,
          isLoaded: true
        });
      });
    })
  }

  useEffect(()=>{
    handleCart()
  }, [])

  if (values.isLoaded) {
    if ((values.products.length > 0) || (cart.cart_nb > 0)) {
      return (
        <Box border={1} borderColor="grey.200" borderRadius={5} className={classes.paper}>
          <div className={classes.container}>
            <ShoppingcartIcon className={classes.buttonSvg2} />
            <div className={classes.class}>{translations["link_shoppingcart"]}</div>
          </div>
          <div>
            <List component="nav" aria-label="mailbox folders">
            {values.products.map((value, index) => (
              <ListItem>
                <ListItemText
                  primary={translations["charniere"]}
                  secondary={value['attributes']['att5'] + ' - ' + translations["link_projects_longueur"] + ' ' + value['longueur'] + 'mm'}
                />
                <ListItemText
                  className={classes.classTotal}
                  primary={value['quantity']}
                  secondary=<NumberFormat value={value['price']} displayType={'text'} thousandSeparator={false} decimalScale={2} suffix={'€'} />
                />
              </ListItem>
            ))}
            </List>
          </div>
        </Box>
      );
    } else {
      return (
        <Box border={1} borderColor="grey.200" borderRadius={5} className={classes.paper}>
          <div className={classes.container}>
            <ShoppingcartIcon className={classes.buttonSvg2} />
            <div className={classes.class}>{translations["link_shoppingcart"]}</div>
          </div>
          <div>
            <div className={classes.class}>{translations["empty_cart"]}</div>
          </div>
        </Box>
      );
    }
  } else {
    return null;
  }
}

const mapStateToProps = function(state) {
  return {
    language: state.paramsReducer.language,
    content: state.paramsReducer.content,
    logged: state.accountReducer.logged,
    details: state.accountReducer.details,
    cart: state.accountReducer.cart,
    cartdetail: state.accountReducer.cartdetail,
    base: state.paramsReducer.base,
  }
}

export default connect(mapStateToProps)(Cart);
