import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import MetaTags from 'react-meta-tags';
import { Translation } from 'react-i18next';
import styles from '../styles/app.module.css';
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext } from 'pure-react-carousel';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import imageindex from '../Svg/index.svg';
import Stepper from '../Components/Stepper';
import HeaderCart from '../Components/HeaderCart';
import Divider from '@material-ui/core/Divider';
import CartView from '../Components/CartView';
import TextField from '@material-ui/core/TextField';
import InputBase from '@material-ui/core/InputBase';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import { HeadIcon, ShoppingcartIcon, CircleIcon, InvoiceIcon, BookIcon, ValidationIcon, MoneyIcon, ShipIcon } from '../Svg/Icons';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import NumberFormat from 'react-number-format';
import { getOrderValidation, getOrderAtos } from '../Api/RestApi'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
  useHistory,
  useLocation
} from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  account_content: {
    margin: theme.spacing(4),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(5),
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
    backgroundColor: "#fff",
    [theme.breakpoints.down('xs')]: {
       margin: theme.spacing(1),
       padding: theme.spacing(2),
    },
  },
  paper: {
    padding: theme.spacing(4),
    [theme.breakpoints.down('xs')]: {
       paddingLeft: theme.spacing(2),
       paddingRight: theme.spacing(2),
    },
  },
  paper2: {
    padding: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
       paddingLeft: theme.spacing(2),
       paddingRight: theme.spacing(2),
    },
    marginBottom: theme.spacing(4),
  },
  container: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-start',
    flexWrap: 'nowrap',
    height: '35px',
    marginBottom: theme.spacing(4),
  },
  container2: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-start',
    flexWrap: 'nowrap',
    height: '35px',
    marginTop: theme.spacing(4),
    marginBottom: 0,
    padding: theme.spacing(2),
  },
  header_class: {
    fontSize: '24px',
    margin: '0',
    padding: '0',
    lineHeight: '30px',
    marginBottom: '3px',
    fontWeight: theme.typography.fontWeightBold,
    letterSpacing: '2px',
    margin: theme.spacing(3),
    [theme.breakpoints.down('xs')]: {
       margin: theme.spacing(1),
       fontSize: '16px',
       lineHeight: '22px',
    },
  },
  header_content: {
    margin: theme.spacing(2),
    marginLeft: theme.spacing(4),
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-start',
    [theme.breakpoints.down('xs')]: {
       margin: theme.spacing(1),
    },
  },
  button: {
    fontSize: '15px',
    borderRadius: '30px',
    marginTop: theme.spacing(3),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    height: '60px',
    backgroundColor: theme.palette.success.main,
    color: theme.palette.success.contrastText,
    fontWeight: theme.typography.fontWeightBold,
    '&:hover': {
      backgroundColor: theme.palette.success.main,
      color: theme.palette.success.contrastText,
    },
  },
  buttonSvg: {
    fontSize: '20px',
    fill: theme.palette.primary.main,
    stroke: theme.palette.primary.main,
  },
  buttonSvg1: {
    fontSize: '35px',
    fill: theme.palette.primary.main,
    stroke: theme.palette.primary.main,
  },
  buttonSvg2: {
    fontSize: '30px',
    fill: 'none',
    stroke: theme.palette.secondary.main,
  },
  class: {
    fontSize: '13px',
    marginLeft: theme.spacing(2),
    lineHeight: '20px',
    marginBottom: '3px',
    fontWeight: theme.typography.fontWeightBold,
    textTransform: 'uppercase',
    letterSpacing: '2px',
    color: theme.palette.secondary.contrastText
  },
  legal: {
    fontSize: '13px',
    lineHeight: '20px',
    marginTop: theme.spacing(4),
    fontWeight: theme.typography.fontWeightLight,
    textTransform: 'uppercase',
    letterSpacing: '2px'
  },
  boxhead: {
    marginBottom: theme.spacing(4),
  },
  head: {
    fontSize: '13px',
    fontWeight: theme.typography.fontWeightBold,
    textTransform: 'uppercase',
    letterSpacing: '2px',
    marginBottom: theme.spacing(1),
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'space-between'
  },
  form: {
    borderRadius: '30px',
    marginTop: theme.spacing(3),
    backgroundColor: theme.palette.light.main,
    color: theme.palette.light.contrastText,
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    height: '60px',
  },
  circle: {
    width: '60px',
    height: '60px',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: 'auto',
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(3),
  },
  buttonLogin: {
    fontSize: '26px',
    fill: theme.palette.navtextcolor.main,
    stroke: theme.palette.navtextcolor.main,
  },
  buttonLogin2: {
    fontSize: '26px',
    fill: theme.palette.grey.A700,
    stroke: theme.palette.grey.A700,
  },
  error: {
    fontSize: '13px',
    fontWeight: theme.typography.fontWeightNormal,
    paddingLeft: theme.spacing(3),
    color: theme.palette.error.main,
  },
  switch: {
    fontSize: '13px',
    margin: theme.spacing(1),
    lineHeight: '20px',
    fontWeight: theme.typography.fontWeightRegular,
    textTransform: 'uppercase',
    letterSpacing: '2px'
  },
  switchlabel: {
    fontSize: '15px',
    margin: theme.spacing(1),
    lineHeight: '20px',
    fontWeight: theme.typography.fontWeightRegular,
    letterSpacing: '1px'
  },
  switchMargin: {
    height: '60px',
    marginTop: theme.spacing(3),
  },
  legal_container: {
    marginTop: theme.spacing(7),
  },
  success: {
    color: theme.palette.light.contrastText,
  },
  buttonValid: {
    fontSize: '15px',
    borderRadius: '30px',
    marginTop: theme.spacing(3),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    height: '60px',
    backgroundColor: theme.palette.success.main,
    color: theme.palette.success.contrastText,
    fontWeight: theme.typography.fontWeightBold,
    '&:hover': {
      backgroundColor: theme.palette.success.main,
      color: theme.palette.success.contrastText,
    },
  },
  classTotal: {
    textAlign: 'right',
    paddingLeft: theme.spacing(2),
  },
}));

function Validation(props) {
  const classes = useStyles();
  const { language, translations, logged, details, shipping, countries } = props
  const [locale, setLocale] = React.useState(language);
  let history = useHistory();

  const [values, setValues] = React.useState({
    isDisabled : true,
    isLoaded: false,
    products: 0,
    box: 0,
    shipping: 0,
    shippingname: '',
    baseht: 0,
    vat: 0,
    total: 0,
  });

  const handleShipping = () => {
    getOrderValidation(details.id, details.email, language, window.location.hostname).then(data => {
      setValues({ ...values,
        checkedCgv: false,
        products: data.products,
        box: data.box,
        shipping: data.shipping,
        shippingname: data.shippingname,
        baseht: data.sumht,
        vat: data.vat,
        total: data.sum,
        atos: '',
        url: data.url,
        datas: data.datas,
        version: data.version,
        seal: data.seal,
        shipping_title: data.shipping_title,
        shipping_street: data.shipping_street,
        payment_title: data.payment_title,
        payment_street: data.payment_street,
        isLoaded: true,
      });
    })
  }

  const handleAtos = () => {

  }

  const handleChange = (event) => {
    if (event.target.checked) {
      var isDisableded = false
    } else {
      var isDisableded = true
    }
    setValues({ ...values, [event.target.name]: event.target.checked, isDisabled: isDisableded });
  };

  useEffect(()=>{
    handleShipping()
    window.scrollTo(0, 0);
  }, [])

  if (!logged) {
    return (
      <Redirect to={{ pathname: "/" + language  + "/" + translations["link_login_url"] }} />
    )
  } else {
    if (values.isLoaded) {
      return (
        <div>
          <MetaTags>
            <title>{translations["link_payment"]}</title>
            <meta name="description" content="Some description." />
            <meta property="og:title" content={translations["link_payment"]} />
            <meta property="og:image" content="path/to/image.jpg" />
          </MetaTags>
          <div>
            <Grid container spacing={0} justify="space-between" alignItems="center" alignContent="center">
              <Grid item xs={12} lg={5} className={classes.header_content}>
                <ValidationIcon className={classes.buttonSvg1} />
                <h1 className={classes.header_class}>{translations["link_payment"]}</h1>
              </Grid>
              <Grid item xs={12} lg={5}>
                <HeaderCart translations={translations} />
              </Grid>
            </Grid>
          </div>
          <div className={classes.account_content}>
            <Stepper translations={translations} step={3} />
            <div className={classes.root}>
              <Grid container spacing={5}>
                <Grid item xs={12} lg={7}>
                  <Grid container spacing={5}>
                    <Grid item xs={12} xl={6}>
                      <Box border={1} borderColor="grey.200" borderRadius={5} className={classes.paper2}>
                        <div className={classes.container2}>
                          <ShipIcon className={classes.buttonSvg1} />
                          <div className={classes.class}>{translations["link_shipping"]}</div>
                        </div>
                        <div className={classes.container2}>
                          <h3>{values.shipping_title}</h3>
                        </div>
                        <div className={classes.container2}>
                          <p>{values.shipping_street}</p>
                        </div>
                      </Box>
                    </Grid>
                    <Grid item xs={12} xl={6}>
                      <Box border={1} borderColor="grey.200" borderRadius={5} className={classes.paper2}>
                        <div className={classes.container2}>
                          <InvoiceIcon className={classes.buttonSvg1} />
                          <div className={classes.class}>{translations["link_payment"]}</div>
                        </div>
                        <div className={classes.container2}>
                          <h3>{values.payment_title}</h3>
                        </div>
                        <div className={classes.container2}>
                          <p>{values.payment_street}</p>
                        </div>
                      </Box>
                    </Grid>
                  </Grid>
                  <CartView translations={translations} />
                </Grid>
                <Grid item xs={12} lg={5}>
                  <Box border={1} borderColor="grey.200" borderRadius={5} className={classes.paper2}>
                    <div className={classes.container2}>
                      <MoneyIcon className={classes.buttonSvg1} />
                      <div className={classes.class}>{translations["total"]}</div>
                    </div>
                    <div>
                      <List component="nav" aria-label="addresses">
                        <ListItem>
                          <ListItemText
                            primary={translations["total_products"]}
                          />
                          <ListItemText
                            className={classes.classTotal}
                            primary=<NumberFormat value={values.products} displayType={'text'} thousandSeparator={false} decimalScale={2} suffix={'€'} />
                          />
                        </ListItem>
                        <ListItem>
                          <ListItemText
                            primary={translations["total_box"]}
                          />
                          <ListItemText
                            className={classes.classTotal}
                            primary=<NumberFormat value={values.box} displayType={'text'} thousandSeparator={false} decimalScale={2} suffix={'€'} />
                          />
                        </ListItem>
                        <ListItem>
                          <ListItemText
                            primary={values.shippingname}
                          />
                          <ListItemText
                            className={classes.classTotal}
                            primary=<NumberFormat value={values.shipping} displayType={'text'} thousandSeparator={false} decimalScale={2} suffix={'€'} />
                          />
                        </ListItem>
                        <Divider />
                        <ListItem>
                          <ListItemText
                            primary={translations["total_ht"]}
                          />
                          <ListItemText
                            className={classes.classTotal}
                            primary=<NumberFormat value={values.baseht} displayType={'text'} thousandSeparator={false} decimalScale={2} suffix={'€'} />
                          />
                        </ListItem>
                        <ListItem>
                          <ListItemText
                            primary={translations["total_vat"]}
                          />
                          <ListItemText
                            className={classes.classTotal}
                            primary=<NumberFormat value={values.vat} displayType={'text'} thousandSeparator={false} decimalScale={2} suffix={'€'} />
                          />
                        </ListItem>
                        <Divider />
                        <ListItem>
                          <ListItemText
                            primary={translations["total_to_payed"]}
                          />
                          <ListItemText
                            className={classes.classTotal}
                            primary=<NumberFormat value={values.total} displayType={'text'} thousandSeparator={false} decimalScale={2} suffix={'€'} />
                          />
                        </ListItem>
                      </List>
                    </div>
                  </Box>
                  <FormControlLabel
                    control={
                    <Checkbox
                      checked={values.checkedCgv}
                      onChange={handleChange}
                      name="checkedCgv"
                      color="primary"
                    />}
                    label={translations["cgv_check"]}
                  />
                  <form name="redirectForm" action={values.url} method="post">
                     <input type="hidden" name="Data" value={values.datas} />
                     <input type="hidden" name="InterfaceVersion" value={values.version} />
                     <input type="hidden" name="Seal" value={values.seal} />
                     <Button type="submit" disabled={values.isDisabled} fullWidth variant="contained" color="inherit" className={classes.buttonValid}>{translations["order_next"]}</Button>
                  </form>
                </Grid>
              </Grid>
            </div>
            <div className={classes.legal}>{translations["link_login_legal"]}</div>
          </div>
        </div>
      )
    } else {
      return null;
    }
  }
}

const mapStateToProps = function(state) {
  return {
    language: state.paramsReducer.language,
    content: state.paramsReducer.content,
    logged: state.accountReducer.logged,
    details: state.accountReducer.details,
    shipping: state.accountReducer.shipping,
    base: state.paramsReducer.base,
  }
}

export default connect(mapStateToProps)(Validation);
