import React from 'react';

class NavBar extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      base: '',
      isLoading: false,
    };
  }

  render() {
    if (this.state.isLoading) {
      return null
    } else {
      return this.state.base
    }
  }
}

export default NavBar;
