import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { MadeIcon, ShipIcon, PaymentIcon, AssitanceIcon } from '../Svg/Icons';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap',
    marginTop: theme.spacing(4),
    backgroundColor: theme.palette.footercolor.main,
  },
  container: {
    margin: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'nowrap',
    minWidth: '160px',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      margin: theme.spacing(1),
    },
  },
  class: {
    fontSize: '12px',
    marginLeft: theme.spacing(1),
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center',
      margin: theme.spacing(1),
    },
    color: theme.palette.footertextcolor.main,
  },
  buttonSvg1: {
    fontSize: '45px',
    fill: theme.palette.footertextcolor.main,
    stroke: theme.palette.footertextcolor.main,
  },
  buttonSvg2: {
    fontSize: '55px',
    fill: theme.palette.footertextcolor.main,
    stroke: theme.palette.footertextcolor.main,
  },
  buttonSvg3: {
    fontSize: '50px',
    fill: theme.palette.footertextcolor.main,
    stroke: theme.palette.footertextcolor.main,
    marginTop: '6px'
  },
}));

export function ReAssurance(props) {
  const classes = useStyles(props);
  const { blocks } = props;
  return (
  <div className={classes.root}>
    <div className={classes.container}>
      <MadeIcon className={classes.buttonSvg1} />
      <p className={classes.class} dangerouslySetInnerHTML={{__html: blocks['footer_reassurance_1'].title}}></p>
    </div>
    <div className={classes.container}>
      <ShipIcon className={classes.buttonSvg2} />
      <p className={classes.class} dangerouslySetInnerHTML={{__html: blocks['footer_reassurance_2'].title}}></p>
    </div>
    <div className={classes.container}>
      <PaymentIcon className={classes.buttonSvg3} />
      <p className={classes.class} dangerouslySetInnerHTML={{__html: blocks['footer_reassurance_3'].title}}></p>
    </div>
    <div className={classes.container}>
      <AssitanceIcon className={classes.buttonSvg1} />
      <p className={classes.class} dangerouslySetInnerHTML={{__html: blocks['footer_reassurance_4'].title}}></p>
    </div>
  </div>
  );
}
